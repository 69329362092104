import { Outlet, createRootRouteWithContext, ParsedLocation } from "@tanstack/react-router";
import { Error500Component } from "@/core/errors/components/Error500Component";
import { Error404Component } from "@/core/errors/components/Error404Component";
import { Error406Component } from "@/core/errors/components/Error406Component";
import { ThemeProvider } from "@/core/shadcn/theme-provider";
import { MainMenuUI } from "@/features/main-menu/ui";
import { Toaster } from "sonner";
import React from "react";

const TanStackRouterDevtools =
  process.env.NODE_ENV === "production"
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      );

interface RootContext {
  breadcrumbs: Map<string, { displayName: string; location: ParsedLocation }>;
}

export const Route = createRootRouteWithContext<RootContext>()({
  component: RootComponent,
  errorComponent: Error500Component,
  notFoundComponent: Error404Component,
});

function RootComponent() {
  return (
    <Error406Component>
      <ThemeProvider defaultTheme="dark">
        <Toaster
          expand
          visibleToasts={1}
          position="top-center"
          richColors
          toastOptions={{
            unstyled: false,
          }}
        />
        <MainMenuUI>
          <Outlet />
        </MainMenuUI>
        <TanStackRouterDevtools />
      </ThemeProvider>
    </Error406Component>
  );
}
