import { TruncatedText } from "@/core/components/truncated-text";
import { Avatar, AvatarFallback, AvatarImage } from "@/core/shadcn/ui/avatar";
import { Badge } from "@/core/shadcn/ui/badge";
import { Button } from "@/core/shadcn/ui/button";
import {
  Select,
  SelectContent,
  SelectTrigger,
  SelectItem,
  SelectValue,
  SelectGroup,
  SelectLabel,
  SelectSeparator,
} from "@/core/shadcn/ui/select";
import { acronym, compactNumber } from "@/core/utils/tailwind-toolkit/utils";
import { cn } from "@/core/utils/tailwind-toolkit/core";
import { Bookmark } from "lucide-react";
import { Skeleton } from "@/core/shadcn/ui/skeleton";
import { legend } from "@/core/utils/legend-state/core";
import { Card, CardContent, CardHeader } from "@/core/shadcn/ui/card";
import { SearchXIcon } from "lucide-react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/core/shadcn/ui/dialog";
import { Label } from "@/core/shadcn/ui/label";
import dayjs from "dayjs";
import { LoadingSpinner } from "@/core/components/loading-spinner";
import { capitalize } from "radash";
import { state } from "../../state/state";
import { TransformedCompany } from "../../state/types";
import { testIds } from "../../test-ids";
import { env } from "@/env";

export function NoResultsFound() {
  const email = env.VITE_REQUEST_EMAIL;
  const subject = encodeURIComponent("Deltabase new company data request");
  const body = encodeURIComponent(`Company: Sainsbury's
URL: sainsburys.co.uk
Description: Supermarket, online retail and banking group

Add more companies below using the above format.
`);

  const handleOnClickButton = () => {
    window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
  };

  return (
    <Card
      {...testIds.components.resultsTable.noResults.container}
      className="w-full mx-auto text-center p-6 shadow-none border-dashed border-0 border-b-1 rounded-none border-border"
    >
      <CardHeader className="pb-2">
        <div className="flex justify-center mb-4">
          <Button variant="outline" size="icon" className="pointer-events-none shadow-sm">
            <SearchXIcon className="w-6 h-6" />
          </Button>
        </div>
        <span {...testIds.components.resultsTable.noResults.title} className="text-lg font-semibold">
          No Companies Found
        </span>
      </CardHeader>
      <CardContent className="flex flex-col items-center">
        <p
          {...testIds.components.resultsTable.noResults.description}
          className="text-muted-foreground mb-6 text-sm max-w-[352px]"
        >
          Can't find the company you're looking for? Click below to send an email request for a new company.
        </p>
        <div className="flex gap-4 justify-center">
          <Dialog>
            <DialogTrigger asChild>
              <Button variant="secondary">Create Company Request</Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle className="mb-3">Company Request Template</DialogTitle>
                <div
                  {...testIds.components.resultsTable.noResults.emailInstructions}
                  className="bg-muted dark:bg-accent/45 p-4 rounded-lg text-sm"
                >
                  To request a company to be added to your data set, please email
                  <a
                    {...testIds.components.resultsTable.noResults.emailLink}
                    href={`mailto:${email}?subject=${subject}&body=${body}`}
                    target="_top"
                    className="ml-1 underline"
                  >
                    platform.orders@deltabase.io
                  </a>
                  . Include the Company Name, Description and website URL. A member of the team will then reach out to
                  confirm your order and next steps.
                </div>
              </DialogHeader>
              <div className="flex flex-col gap-4">
                <div className="space-y-2">
                  <Label>Request Email</Label>
                  <div className="flex items-center gap-2">
                    <div
                      {...testIds.components.resultsTable.noResults.emailDisplay}
                      className="flex-1 p-2 border rounded-md bg-background"
                    >
                      <span className="text-sm">{email}</span>
                    </div>
                    <Button onClick={handleOnClickButton} size="default">
                      Open in Mail
                    </Button>
                  </div>
                </div>
                <div className="space-y-2">
                  <Label>Example Email</Label>
                  <div
                    {...testIds.components.resultsTable.noResults.exampleEmail.container}
                    className="p-3 border rounded-md space-y-2"
                  >
                    <p
                      {...testIds.components.resultsTable.noResults.exampleEmail.subject}
                      className="text-sm font-medium"
                    >
                      Subject: Deltabase new company data request
                    </p>
                    <div className="space-y-1 text-sm">
                      <p {...testIds.components.resultsTable.noResults.exampleEmail.company}>
                        <span className="font-medium">Company:</span> Sainsbury's
                      </p>
                      <p {...testIds.components.resultsTable.noResults.exampleEmail.url}>
                        <span className="font-medium">URL:</span> sainsburys.co.uk
                      </p>
                      <p {...testIds.components.resultsTable.noResults.exampleEmail.description}>
                        <span className="font-medium">Description:</span> Supermarket, online retail and banking group
                      </p>
                    </div>
                    <p
                      {...testIds.components.resultsTable.noResults.exampleEmail.note}
                      className="text-xs italic text-muted-foreground"
                    >
                      Add more companies below using the above format.
                    </p>
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </CardContent>
    </Card>
  );
}

interface ResultsPaginationProps {}

const ResultsPagination = legend.react.observer((props: ResultsPaginationProps) => {
  function handlePrev() {
    state.actions.pagination.decrement();
  }

  function handleNext() {
    state.actions.pagination.increment();
  }

  function handleStart() {
    state.actions.pagination.decrementToStart();
  }

  function handleEnd() {
    state.actions.pagination.incrementToEnd();
  }

  return (
    <div
      {...testIds.components.resultsTable.pagination.container}
      className="flex items-center justify-between px-3 h-[58px] border-t border-border"
    >
      <div {...testIds.components.resultsTable.pagination.controls.prev} className="flex items-center gap-2">
        <legend.react.Memo>
          {() => {
            return (
              <Button
                variant="outline"
                className="w-[72px] h-[32px] text-xs font-medium dark:bg-[#27272A]/35 bg-[#27272A]/5 rounded-[6px]"
                disabled={
                  state.local$.pagination.data.current_page.get() === 1 ||
                  state.remote$.companies.pending.get() ||
                  state.remote$.companies.data.cached_transformed.get().length === 0
                }
                onClick={handlePrev}
              >
                Prev
              </Button>
            );
          }}
        </legend.react.Memo>
        <legend.react.Memo>
          {() => {
            return (
              <Button
                variant="outline"
                className="w-[72px] h-[32px] text-xs font-medium dark:bg-[#27272A]/35 bg-[#27272A]/5 rounded-[6px]"
                disabled={
                  state.local$.pagination.data.current_page.get() === state.local$.pagination.data.total_pages.get() ||
                  state.remote$.companies.pending.get() ||
                  state.remote$.companies.data.cached_transformed.get().length === 0
                }
                onClick={handleNext}
              >
                Next
              </Button>
            );
          }}
        </legend.react.Memo>
      </div>
      <span {...testIds.components.resultsTable.pagination.info} className="text-xs font-medium text-muted-foreground">
        {`Showing ${state.local$.pagination.data.total_count.get() === 0 ? 0 : state.local$.pagination.data.page_start.get() + 1}-${Math.min(
          state.local$.pagination.data.page_end.get(),
          state.local$.pagination.data.total_count.get(),
        )} of ${state.local$.pagination.data.total_count.get()} results`}
      </span>
      <div {...testIds.components.resultsTable.pagination.controls.start} className="flex items-center gap-2">
        <legend.react.Memo>
          {() => {
            return (
              <Button
                variant="outline"
                className="w-[72px] h-[32px] text-xs font-medium dark:bg-[#27272A]/35 bg-[#27272A]/5 rounded-[6px]"
                disabled={
                  state.local$.pagination.data.current_page.get() === 1 ||
                  state.remote$.companies.pending.get() ||
                  state.remote$.companies.data.cached_transformed.get().length === 0
                }
                onClick={handleStart}
              >
                Start
              </Button>
            );
          }}
        </legend.react.Memo>
        <legend.react.Memo>
          {() => {
            return (
              <Button
                variant="outline"
                className="w-[72px] h-[32px] text-xs font-medium dark:bg-[#27272A]/35 bg-[#27272A]/5 rounded-[6px]"
                disabled={
                  state.local$.pagination.data.current_page.get() === state.local$.pagination.data.total_pages.get() ||
                  state.remote$.companies.pending.get() ||
                  state.remote$.companies.data.cached_transformed.get().length === 0
                }
                onClick={handleEnd}
              >
                End
              </Button>
            );
          }}
        </legend.react.Memo>
      </div>
    </div>
  );
});

interface ResultsCardProps {
  isInPeerSet?: boolean;
  company?: {
    name?: string;
    logo?: string;
    score?: {
      label?: string | null;
      value?: string | null;
    };
    description?: string;
    tags?: Array<{
      label?: string;
      variant?: "secondary" | "outline" | "destructive";
      hide?: boolean;
    }>;
  };
  onPeerSetToggle?: () => void;
}

const ResultsCard = (props: ResultsCardProps) => {
  return (
    <div
      {...testIds.components.resultsTable.companyCard.container}
      className="flex flex-col gap-2 px-3 py-2 border-0 border-b border-border cursor-pointer hover:bg-accent/60 dark:hover:bg-[#27272A]/25"
      onClick={props.onPeerSetToggle}
    >
      <div {...testIds.components.resultsTable.companyCard.header} className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Avatar className="h-8 w-8 rounded-[6px] border border-border dark:border-0">
            <AvatarImage src={props.company?.logo} alt={`${props.company?.name} logo`} />
            <AvatarFallback className="rounded-[6px] text-sm">{acronym(props.company?.name, 2)}</AvatarFallback>
          </Avatar>
          <div className="flex flex-col gap-0">
            <span className="text-sm font-medium leading-tight">{props.company?.name}</span>
            <span className="text-xs font-[300] text-muted-foreground">
              {props.company?.score?.label}
              <span className="text-foreground ml-1 font-[600]">{props.company?.score?.value}</span>
            </span>
          </div>
        </div>
        <Button
          size="icon"
          variant="default"
          testId={testIds.components.resultsTable.companyCard.peerSetToggle["data-testid"]}
          onClick={(e) => {
            e.stopPropagation();
            props.onPeerSetToggle?.();
          }}
          className={cn(
            "text-xs h-9 px-3 border-border border justify-start  ",
            props.isInPeerSet
              ? "border-0"
              : "bg-white shadow-sm hover:bg-accent/60 dark:bg-[#27272A]/35 dark:hover:bg-[#27272A]/35 dark:text-foreground text-foreground",
          )}
        >
          <Bookmark className={cn("size-3 mr-0", props.isInPeerSet && "fill-white dark:fill-black")} />
          {/* {props.isInPeerSet ? "Remove" : "Add to Peer Set"} */}
        </Button>
      </div>
      <div {...testIds.components.resultsTable.companyCard.description}>
        <TruncatedText
          characterLimit={220}
          classNames={{
            text: "text-xs font-[350] leading-[140%] max-w-[830px]",
            readMore: "font-[500]",
          }}
        >
          {props.company?.description ?? ""}
        </TruncatedText>
      </div>
      <div {...testIds.components.resultsTable.companyCard.tags} className="flex items-center gap-1.5 flex-wrap">
        {props.company?.tags?.map((tag, index) =>
          !tag.hide ? (
            <Badge
              key={`${tag.label}-${index}`}
              variant={tag.variant === "destructive" ? "outline" : tag.variant || "secondary"}
              className={cn(
                "text-[11px] px-1.5 py-0 h-[18px] font-medium",
                tag.variant === "secondary" && "border border-border",
                tag.variant === "destructive" && "text-red-600 border-red-600",
              )}
            >
              {tag.label}
            </Badge>
          ) : null,
        )}
      </div>
    </div>
  );
};

const ResultsCardSkeleton = () => {
  return (
    <div className="flex flex-col gap-2 px-3 py-2 border-0 border-b border-border last:border-b-0">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Skeleton className="h-8 w-8 rounded-[6px]" />
          <div className="flex flex-col gap-0.5">
            <Skeleton className="h-3.5 w-24" />
            <Skeleton className="h-3 w-32" />
          </div>
        </div>
        <Skeleton className="h-7 w-32" />
      </div>
      <div className="space-y-1.5 max-w-[830px]">
        <Skeleton className="h-3 w-full" />
        <Skeleton className="h-3 w-[80%]" />
      </div>
      <div className="flex items-center gap-1.5">
        <Skeleton className="h-[18px] w-16 rounded-full" />
        <Skeleton className="h-[18px] w-16 rounded-full" />
      </div>
    </div>
  );
};

interface TabButtonProps {
  isActive: boolean;
  count?: number;
  label: string;
  pending?: boolean;
  onClick?: () => void;
}

const TabButton = ({ isActive, count, label, pending, onClick }: TabButtonProps) => (
  <Button
    variant="ghost"
    className={cn(
      "text-xs font-medium text-muted-foreground px-3 py-[6px] rouned-[6px] h-[32px] ",
      isActive
        ? " bg-white cursor-default  shadow-sm hover:bg-white dark:bg-background dark:hover:bg-background text-foreground"
        : "hover:bg-transparent",
    )}
    onClick={onClick}
  >
    {label} {pending ? <LoadingSpinner className="size-3" /> : `(${compactNumber(count)})`}
  </Button>
);

export const ResultsHeader = legend.react.observer(() => {
  const tab = state.local$.tab.get();

  function handleSortChange(value: string) {
    state.actions.sort.setSortFromUI(value);
  }

  function handleTabChange(value: "peerset" | "companies") {
    state.local$.tab.set(value);
  }

  return (
    <div
      {...testIds.components.resultsTable.header}
      className="flex items-center justify-between px-3 h-[58px] border-b border-border dark:bg-[#27272A]/25 bg-accent rounded-t-[6px]"
    >
      <div {...testIds.components.resultsTable.tabButtons.container} className="flex gap-0">
        <TabButton
          {...testIds.components.resultsTable.tabButtons.allCompanies}
          isActive={tab === "companies"}
          count={state.local$.pagination.data.total_count.get()}
          label="Companies"
          pending={state.remote$.companies.pending.get()}
          onClick={() => handleTabChange("companies")}
        />
        <TabButton
          {...testIds.components.resultsTable.tabButtons.peerSet}
          isActive={tab === "peerset"}
          count={Object.keys(state.local$.peerset.data.get()).length}
          label="My Peer Set"
          pending={state.local$.peerset.pending.get()}
          onClick={() => handleTabChange("peerset")}
        />
      </div>
      <legend.react.Switch value={state.local$.tab.get()}>
        {{
          companies: () => (
            <Select
              {...testIds.components.resultsTable.sort.select}
              value={state.actions.sort.mapSortFieldToUI() ?? ""}
              onValueChange={handleSortChange}
            >
              <SelectTrigger
                {...testIds.components.resultsTable.sort.trigger}
                className="w-[200px] h-[32px] text-xs font-medium"
              >
                <SelectValue placeholder="Sort by..." />
              </SelectTrigger>
              <SelectContent align="end">
                <SelectGroup>
                  <SelectLabel>Company Name</SelectLabel>
                  <SelectItem value="company_name (A-Z)">Alphabetically (A-Z)</SelectItem>
                  <SelectItem value="company_name (Z-A)">Alphabetically (Z-A)</SelectItem>
                </SelectGroup>

                <SelectSeparator />

                <SelectGroup>
                  <SelectLabel>Employee Net Sentiment Score (NSS)</SelectLabel>
                  <SelectItem value="nss-high-to-low">NSS: High to Low</SelectItem>
                  <SelectItem value="nss-low-to-high">NSS: Low to High</SelectItem>
                </SelectGroup>

                <SelectSeparator />

                <SelectGroup>
                  <SelectLabel>Employee Review Count</SelectLabel>
                  <SelectItem value="review_count-high-to-low">Count: High to Low</SelectItem>
                  <SelectItem value="review_count-low-to-high">Count: Low to High</SelectItem>
                </SelectGroup>

                <SelectSeparator />

                <SelectGroup>
                  <SelectLabel>Employee Rating</SelectLabel>
                  <SelectItem value="rating-high-to-low">Rating: High to Low</SelectItem>
                  <SelectItem value="rating-low-to-high">Rating: Low to High</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          ),
          peerset: () => null,
        }}
      </legend.react.Switch>
    </div>
  );
});

export const CompaniesTable = legend.react.observer(() => {
  const companies = state.remote$.companies.data.cached_transformed.get();

  function mapSortToScore(company: TransformedCompany) {
    const field = state.remote$.companies.params.sort_field.get();
    if (field === "count_per_company") {
      const strength = company.count_per_company.strength ?? "";
      const value = company.count_per_company.compact_number;
      const isUnknown = !strength || !value;
      return {
        label: isUnknown ? "Reviews unknown" : `Reviews ${capitalize(strength)}:`,
        value: isUnknown ? "" : value,
      };
    } else if (field === "rating_per_company") {
      const value = company.rating_per_company.rating?.toString();
      const isUnknown = !value;
      return {
        label: isUnknown ? "Rating unknown" : `Rating:`,
        value: isUnknown ? "" : value,
      };
    } else {
      const strength = company.nss_per_company.strength ?? "";
      const value = company.nss_per_company.percentage_string;
      const isUnknown = !strength || !value;
      return {
        label: isUnknown ? "NSS unknown" : `NSS ${capitalize(strength)}:`,
        value: isUnknown ? "" : value,
      };
    }
  }

  function handleChangePeerSet(company: TransformedCompany) {
    if (state.local$.peerset.data.get()[company.company_id] !== undefined) {
      state.actions.peerset.remove(company.company_id);
    } else {
      state.actions.peerset.add(company);
    }
  }
  function sentimentLabel(sentiment: string) {
    return sentiment === "pos" ? "Positive" : sentiment === "neg" ? "Negative" : "Neutral";
  }
  return companies.map((company) => {
    return (
      <ResultsCard
        key={company.company_id}
        isInPeerSet={state.local$.peerset.data.get()[company.company_id] !== undefined}
        onPeerSetToggle={() => handleChangePeerSet(company)}
        company={{
          name: company.company_name,
          logo: company.company_logo_src,
          score: mapSortToScore(company),
          description: company.company_description,
          tags: [
            ...company.sectors.primary.map((sector) => ({
              label: sector.sector_name,
              variant: "secondary" as const,
            })),
            {
              label: `Captured: ${company.review_data_last_capture_date ? dayjs(company.review_data_last_capture_date).format("MMM/YYYY") : "Unknown"}`,
              variant: company.review_data_last_capture_date ? "outline" : "destructive",
            },
            {
              label: `HQ: ${company.company_headquarters?.label ?? "Unknown"}`,
              variant: company.company_headquarters?.label ? "outline" : "destructive",
            },
            {
              label: `Employees: ${company.company_headcount.compact_number ?? "Unknown"}`,
              variant: company.company_headcount.compact_number ? "outline" : "destructive",
            },
            {
              label: `NSS ${sentimentLabel(company.nss_per_company.sentiment ?? "")}: ${company.nss_per_company.percentage_string ?? "Unknown"} `,
              variant: company.nss_per_company.percentage_string ? "outline" : "destructive",
            },
            {
              label: `Rating: ${company.rating_per_company.rating ?? "Unknown"}`,
              variant: company.rating_per_company.rating ? "outline" : "destructive",
            },
            {
              label: `Reviews ${capitalize(company.count_per_company.strength ?? "")}: ${company.count_per_company.compact_number ?? "Unknown"}`,
              variant:
                company.count_per_company.strength === "low" || !company.count_per_company.compact_number
                  ? "destructive"
                  : "outline",
            },
          ],
        }}
      />
    );
  });
});

const PeerSetTable = legend.react.observer(() => {
  function mapSortToScore(company: TransformedCompany) {
    const field = state.remote$.companies.params.sort_field.get();
    if (field === "count_per_company") {
      const strength = company.count_per_company.strength ?? "";
      const value = company.count_per_company.compact_number;
      const isUnknown = !strength || !value;
      return {
        label: isUnknown ? "Reviews unknown" : `Reviews ${capitalize(strength)}:`,
        value: isUnknown ? "" : value,
      };
    } else if (field === "rating_per_company") {
      const value = company.rating_per_company.rating?.toString();
      const isUnknown = !value;
      return {
        label: isUnknown ? "Rating unknown" : `Rating:`,
        value: isUnknown ? "" : value,
      };
    } else {
      const strength = company.nss_per_company.strength ?? "";
      const value = company.nss_per_company.percentage_string;
      const isUnknown = !strength || !value;
      return {
        label: isUnknown ? "NSS unknown" : `NSS ${capitalize(strength)}:`,
        value: isUnknown ? "" : value,
      };
    }
  }

  function handleChangePeerSet(company: TransformedCompany) {
    if (state.local$.peerset.data.get()[company.company_id] !== undefined) {
      state.actions.peerset.remove(company.company_id);
    } else {
      state.actions.peerset.add(company);
    }
  }

  function sentimentLabel(sentiment: string) {
    return sentiment === "pos" ? "Positive" : sentiment === "neg" ? "Negative" : "Neutral";
  }

  return Object.values(state.local$.peerset.data.get()).map((company) => {
    return (
      <ResultsCard
        key={company.company_id}
        isInPeerSet={true}
        onPeerSetToggle={() => handleChangePeerSet(company)}
        company={{
          name: company.company_name,
          logo: company.company_logo_src,
          score: mapSortToScore(company),
          description: company.company_description,
          tags: [
            ...company.sectors.primary.map((sector) => ({
              label: sector.sector_name,
              variant: "secondary" as const,
            })),
            {
              label: `Captured: ${dayjs(company.review_data_last_capture_date).format("MMM/YYYY")}`,
              variant: "outline",
              hide: !company.review_data_last_capture_date,
            },
            {
              label: `HQ: ${company.company_headquarters?.label}`,
              variant: "outline",
              hide: !company.company_headquarters?.label,
            },
            {
              label: `Employees: ${company.company_headcount.compact_number}`,
              variant: "outline",
              hide: !company.company_headcount.compact_number,
            },
            {
              label: `NSS ${sentimentLabel(company.nss_per_company.sentiment ?? "")}: ${company.nss_per_company.percentage_string} `,
              variant: "outline",
              hide: !company.nss_per_company.percentage_string,
            },
            {
              label: `Rating: ${company.rating_per_company.rating}`,
              variant: "outline",
              hide: !company.rating_per_company.rating,
            },
            {
              label: `Reviews ${capitalize(company.count_per_company.strength ?? "")}: ${company.count_per_company.compact_number}`,
              variant: company.count_per_company.strength === "low" ? "destructive" : "outline",
              hide: !company.count_per_company.compact_number,
            },
          ],
        }}
      />
    );
  });
});

export const ResultsTable = legend.react.observer(() => {
  const pending = state.remote$.companies.pending.get();
  const companies = state.remote$.companies.data.cached_transformed.get();
  const noResultsFound = !pending && companies.length === 0;
  return (
    <div
      {...testIds.components.resultsTable.container}
      className="rounded-[6px] border border-border flex flex-col h-[calc(100vh-265px)]"
    >
      <ResultsHeader />
      <div
        {...testIds.components.resultsTable.content}
        className="flex-1 overflow-y-auto"
        id="results-scroll-container"
      >
        {state.remote$.companies.pending.get() ? (
          Array.from({ length: state.local$.pagination.data.page_size.get() }).map((_, index) => (
            <ResultsCardSkeleton key={index} />
          ))
        ) : noResultsFound ? (
          <NoResultsFound />
        ) : (
          <legend.react.Switch value={state.local$.tab.get()}>
            {{
              companies: () => <CompaniesTable />,
              peerset: () => <PeerSetTable />,
            }}
          </legend.react.Switch>
        )}
      </div>
      <legend.react.Switch value={state.local$.tab.get()}>
        {{
          companies: () => <ResultsPagination />,
          peerset: () => null,
        }}
      </legend.react.Switch>
    </div>
  );
});
