import { api } from "../shared/api";
import { auth } from "./auth";
import { legend } from "../utils/legend-state/core";

export interface Metadata {
  meta_data_name: string;
  meta_data_value: string;
  meta_data_description: string | null;
  meta_data_parent_value: string | null;
}

interface IState {
  pending: boolean;
  error: boolean;
  data: { raw_response: Metadata[]; cached_transformed: Record<string, Metadata[]> };
}

export const remote$ = legend.state.observable<IState>({
  pending: false,
  error: false,
  data: legend.sync.synced({
    initial: {
      raw_response: [],
      cached_transformed: {},
    },
    get: async () => {
      remote$.pending.set(true);
      const response = await api.deltabase_data_api.metadata.get();
      const metadata: Record<string, Metadata[]> = {};
      response.data.forEach((item) => {
        if (!metadata[item.meta_data_name]) {
          metadata[item.meta_data_name] = [];
        }
        metadata[item.meta_data_name].push(item);
      });
      remote$.pending.set(false);
      return { raw_response: response.data, cached_transformed: metadata };
    },
    waitFor: () => {
      const token = auth.state$.token.get();
      return !!token;
    },
  }),
});

export const metadata = {
  remote$,
};
