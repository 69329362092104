import { lazy } from "react";
import { IRC, IRC_Exportable, IRC_Exportable_PPTX_Chart, IRC_Exportable_PPTX_Table } from "../../../types";
import { legend } from "@/core/utils/legend-state/core";
import { Observable } from "@legendapp/state";
import { createContextProvider } from "@/core/utils/context-api";
import { state, IState as IInstance } from "./instance/state";
import { report_builder } from "@/features/report-builder/state";
import { hslToHex } from "@/core/utils/tailwind-toolkit/utils";
import { metadata } from "@/core/state";
import pptxgen from "pptxgenjs";
import { sanitizeForCSV } from "@/features/report-builder/rcs/helpers";

const RC_ID = "employee_voice_company_ranking";

export const rc: IRC<IInstance.ILocalState$, IInstance.IRemoteState$, { instance_id: string }> = {
  rc_id: RC_ID,
  instance: {
    component: lazy(() =>
      import("./instance/ui/index").then((module) => ({
        default: module.RC_Component,
      })),
    ),
  },
  registry: {
    // state$: Stores local and remote state for all instances
    state$: legend.state.observable<{
      local_instances: Record<string, Observable<IInstance.ILocalState$>>;
      remote_instances: Record<string, Observable<IInstance.IRemoteState$>>;
    }>({
      local_instances: {},
      remote_instances: {},
    }),
    // context: Provides instance context to components
    context: createContextProvider<{
      instance_id: string;
    }>(),
    // actions: Methods for creating/deleting/managing instances
    actions: {
      createInstance: (instance_id, options) => {
        const { local, remote } = state.createState(instance_id, { local: options.local });

        legend.state.batch(() => {
          rc.registry.state$.local_instances[instance_id].set(local);
          rc.registry.state$.remote_instances[instance_id].set(remote);

          report_builder.local.active_rc_registries.assign({
            [RC_ID]: RC_ID,
          });
        });
      },
      deleteInstance: (id) => {
        legend.state.batch(() => {
          rc.registry.state$.remote_instances[id].delete();
          rc.registry.state$.local_instances[id].delete();
          if (rc.registry.actions.isEmpty()) {
            report_builder.local.active_rc_registries[RC_ID].delete();
          }
        });
      },
      cleanUp: () => {
        legend.state.batch(() => {
          rc.registry.state$.remote_instances.set({});
          rc.registry.state$.local_instances.set({});
          report_builder.local.active_rc_registries[RC_ID].delete();
        });
        // TODO: clean up local storage data and remote data if cache is effected
      },
      getInstance: (id) => {
        const remote = rc.registry.state$.remote_instances[id];
        const local = rc.registry.state$.local_instances[id];
        if (!remote) {
          throw new Error(`Remote instance with id ${id} not found`);
        }
        if (!local) {
          throw new Error(`Local instance with id ${id} not found`);
        }
        return { remote, local };
      },
      isEmpty: () => {
        return (
          Object.keys(rc.registry.state$.remote_instances.get()).length === 0 &&
          Object.keys(rc.registry.state$.local_instances.get()).length === 0
        );
      },
      exportInstance: async (id) => {
        const instance = rc.registry.actions.getInstance(id);

        const exportable: IRC_Exportable = {
          folder_path: "Which-companies-lead-and-lag-the-peer-set",
          master: {
            pptx: {
              powerpoint_name: "Which-companies-lead-and-lag-the-peer-set",
              merge_views_to_master_pptx: true,
            },
          },
          formats: [
            {
              pptx: {
                powerpoint_name: "Which-companies-lead-and-lag-the-peer-set",
                slide_data: [
                  {
                    title: "Analysis - Which companies lead and lag the peer set?",
                    table: await helpers.views.analysis.rankings.pptx(instance),
                  },
                  {
                    title: "Evidence - Which companies lead and lag the peer set?",
                    table: await helpers.views.analysis.evidence.pptx(instance),
                  },
                ],
              },
              csv: {
                data: [
                  {
                    name: "Analysis-Data",
                    content: await helpers.views.analysis.rankings.csv(instance),
                  },
                  {
                    name: "Evidence-Data",
                    content: await helpers.views.analysis.evidence.csv(instance),
                  },
                ],
              },
            },
          ],
        };

        return exportable;
      },
    },
  },
};
// #endregion

const helpers = {
  views: {
    analysis: {
      rankings: {
        pptx: async (instance: ReturnType<typeof rc.registry.actions.getInstance>) => {
          // measures_data is a remote observable, so we need to await it whilst it is fetched from the server
          const company_data = await legend.utils.awaitObservable(
            instance.remote.companies.data,
            instance.remote.companies.pending,
            instance.remote.companies.error,
          );

          const current_measure_id = instance.local.views.analysis.selectors.measure.selectedMeasureId.get();
          const current_measure = instance.local.config.measure.measures
            .get()
            .find((measure) => measure.id === current_measure_id);

          const sort_direction = instance.local.views.analysis.selectors.sort_direction.selectedSortDirectionId.get();

          const table: pptxgen.TableRow[] = [];

          table.push([{ text: "rank" }, { text: "Company" }, { text: "Measure Name" }, { text: "Measure Value" }]);

          company_data.forEach((company, idx) => {
            const rank = idx + 1;
            table.push([
              { text: rank.toString() },
              { text: company?.company_name },
              { text: current_measure?.label },
              { text: company.measure_score?.toString() },
            ]);
          });

          const output: IRC_Exportable_PPTX_Table = {
            title: `Employee Voice Company Rankings`,
            data: table,
            options: {
              x: 0.5,
              y: 0.9,
              w: 9.0,
              color: "363636",
              border: { type: "solid" },
              autoPage: true,
            },
          };

          return output;
        },
        csv: async (instance: ReturnType<typeof rc.registry.actions.getInstance>) => {
          // measures_data is a remote observable, so we need to await it whilst it is fetched from the server
          const company_data = await legend.utils.awaitObservable(
            instance.remote.companies.data,
            instance.remote.companies.pending,
            instance.remote.companies.error,
          );

          const current_measure_id = instance.local.views.analysis.selectors.measure.selectedMeasureId.get();
          const current_measure = instance.local.config.measure.measures
            .get()
            .find((measure) => measure.id === current_measure_id);

          const sort_direction = instance.local.views.analysis.selectors.sort_direction.selectedSortDirectionId.get();

          // Create CSV header with measure and topic columns
          const headers = ["Rank", "Company", "Measure", "Measure Value"];
          const csvContent = [headers.join(",")];

          // Create CSV rows with measure and topic values
          company_data.forEach((item, idx) => {
            const rank = idx + 1;

            const rowData = [
              rank.toString(),
              item.company_name,
              current_measure?.label,
              item.measure_score?.toString(),
            ];
            csvContent.push(rowData.join(","));
          });

          const csv = csvContent.join("\n");
          return csv;
        },
      },
      evidence: {
        pptx: async (instance: ReturnType<typeof rc.registry.actions.getInstance>) => {
          // evidence is a remote observable, so we need to await it whilst it is fetched from the server
          const evidenceData = await legend.utils.awaitObservable(
            instance.remote.evidence.data,
            instance.remote.evidence.pending,
            instance.remote.evidence.error,
          );

          // Peerset is not a remote observable, so we can just grab it from the local state
          const peerset = report_builder.remote.peerset.data.get();

          const table: pptxgen.TableRow[] = [];

          table.push([
            { text: "Company" },
            { text: "Group" },
            { text: "Topic" },
            { text: "Sentiment" },
            { text: "Text" },
          ]);

          for (const [group_id, company_extracts] of Object.entries(evidenceData)) {
            for (const [topic_id, topic_extracts] of Object.entries(company_extracts)) {
              for (const extracts of Object.values(topic_extracts)) {
                for (const extract of extracts) {
                  const company = peerset[extract.company_id];
                  table.push([
                    { text: company?.company_name },
                    { text: group_id },
                    { text: extract.topic_name },
                    { text: extract.sentiment_type },
                    { text: extract.text },
                  ]);
                }
              }
            }
          }

          const output: IRC_Exportable_PPTX_Table = {
            title: `Evidence - Which companies lead and lag the peer set?`,
            data: table,
            options: {
              x: 0.5,
              y: 0.9,
              w: 9.0,
              color: "363636",
              border: { type: "solid" },
              autoPage: true,
            },
          };

          return output;
        },
        csv: async (instance: ReturnType<typeof rc.registry.actions.getInstance>) => {
          // evidence is a remote observable, so we need to await it whilst it is fetched from the server
          const evidenceData = await legend.utils.awaitObservable(
            instance.remote.evidence.data,
            instance.remote.evidence.pending,
            instance.remote.evidence.error,
          );

          // Peerset is not a remote observable, so we can just grab it from the local state
          const peerset = report_builder.remote.peerset.data.get();

          // Create CSV headers
          const headers = ["Company", "Group", "Topic", "Sentiment", "Text"];
          const csvContent = [headers.join(",")];

          for (const [group_id, company_extracts] of Object.entries(evidenceData)) {
            for (const [topic_id, topic_extracts] of Object.entries(company_extracts)) {
              for (const extracts of Object.values(topic_extracts)) {
                for (const extract of extracts) {
                  const company = peerset[extract.company_id];
                  const rowData = [
                    sanitizeForCSV(company?.company_name),
                    sanitizeForCSV(group_id),
                    sanitizeForCSV(extract.topic_name),
                    sanitizeForCSV(extract.sentiment_type),
                    sanitizeForCSV(extract.text),
                  ];
                  csvContent.push(rowData.join(","));
                }
              }
            }
          }

          const csv = csvContent.join("\n");
          return csv;
        },
      },
    },
  },
};
