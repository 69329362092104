// Evidence filter should be driven by the analysis filters
// e.g company, topic, timeframe
// Sentiment shouldnt be a filter for evidence as it only shows 1 positive and 1 negative.

import { legend } from "@/core/utils/legend-state/core";
import {
  ILocalState$,
  IRemoteState$,
  ICreateStateProps,
  IActions,
  IObservers,
  local_schema,
  ITransformedContentExtract,
} from "./types";
import { mapMeasureNameToParams, transformMeasureScore, validateAndMergeState } from "./helpers";
import { rc } from "../../rc";
import { auth, metadata } from "@/core/state";
import { api, IAPI, IContentExtract, IMeasure } from "@/core/shared/api";
import { z } from "zod";
import { report_builder } from "@/features/report-builder/state";
import _, { isNil } from "lodash";

const config: z.infer<typeof local_schema>["config"] = {
  company: {
    measure_filter_to_measure_name_map: {
      NSS: {
        default: "NSS_PER_COMPANY_PER_TOPIC",
        withGeoContinent: "NSS_PER_COMPANY_PER_CONTINENT_PER_TOPIC",
        withGeoCountry: "NSS_PER_COMPANY_PER_COUNTRY_PER_TOPIC",
      },
      PREVALENCE: {
        default: "PREVALENCE_PER_COMPANY_PER_TOPIC",
        withGeoContinent: "PREVALENCE_PER_COMPANY_PER_CONTINENT_PER_TOPIC",
        withGeoCountry: "PREVALENCE_PER_COMPANY_PER_COUNTRY_PER_TOPIC",
      },
      RATING: {
        default: "RATING_PER_COMPANY_PER_TOPIC",
        withGeoContinent: "RATING_PER_COMPANY_PER_CONTINENT_PER_TOPIC",
        withGeoCountry: "RATING_PER_COMPANY_PER_COUNTRY_PER_TOPIC",
      },
      REVIEW_COUNT: {
        default: "COUNT_PER_COMPANY_PER_TOPIC",
        withGeoContinent: "COUNT_PER_COMPANY_PER_CONTINENT_PER_TOPIC",
        withGeoCountry: "COUNT_PER_COMPANY_PER_COUNTRY_PER_TOPIC",
      },
    },
    filters: {
      hide: false,
    },
  },
  measure: {
    hide: false,
    default: "NSS",
    measures: [
      {
        id: "NSS",
        label: "Employee Net Sentiment Score (NSS)",
        unit: "%",
        acronym: "NSS",
        showSign: true,
        domain: (args) => {
          return [-100, 100];
        },
        filter: {
          applied: true,
          disabled: false,
        },
      },
      {
        id: "PREVALENCE",
        label: "Topic Frequency ",
        unit: "%",
        acronym: "PREV",
        domain: (args) => {
          if (!args.PREV) return [0, 100];
          if (args.PREV.max < 15) return [0, _.clamp(args.PREV.max + 10, 15)];
          return [0, args.PREV.max + 10];
        },
        filter: {
          applied: true,
          disabled: false,
        },
      },
      {
        id: "RATING",
        label: "Employee Rating",
        domain: (args) => {
          return [0, 5];
        },
        filter: {
          applied: true,
          disabled: false,
        },
      },
      {
        id: "REVIEW_COUNT",
        label: "Employee Review Count",
        domain: undefined,
        compactYAxisValues: true,
        filter: {
          applied: true,
          disabled: false,
        },
      },
    ],
    reference_areas: {
      NSS_PREVALENCE: [
        {
          label: {
            value: "Drivers",
            backgroundColor: {
              light: "#4DC49A",
              dark: "#1A5741",
            },
            textColor: {
              light: "#2F7B62",
              dark: "#B8F3E0",
            },
          },
          x_axis_domain: (args) => {
            if (args.max_prev < 15) return undefined;
            return { x1: 20, x2: 100, y1: 15, y2: args.max_prev + 10 };
          },
          y_axis_domain: (args) => {
            if (args.max_prev < 15) return undefined;
            return { x1: 15, x2: args.max_prev + 10, y1: 20, y2: 100 };
          },
          fill: {
            light: "#6DDBB0",
            dark: "#2F7B62",
          },
          opacity: {
            light: 0.8,
            dark: 0.8,
          },
          isFront: true,
          ifOverflow: "hidden",
        },
        {
          label: {
            value: "Enablers",
            backgroundColor: {
              light: "#8ADBC8",
              dark: "#2F7B62",
            },
            textColor: {
              light: "#1A5741",
              dark: "#B8F3E0",
            },
          },
          x_axis_domain: (args) => {
            if (args.max_prev < 15) return { x1: 20, x2: 100, y1: 0, y2: _.clamp(args.max_prev + 10, 15) };
            return { x1: 20, x2: 100, y1: 0, y2: 15 };
          },
          y_axis_domain: (args) => {
            if (args.max_prev < 15) return { x1: 0, x2: _.clamp(args.max_prev + 10, 15), y1: 20, y2: 100 };
            return { x1: 0, x2: 15, y1: 20, y2: 100 };
          },
          fill: {
            light: "#B8F3E0",
            dark: "#314741",
          },
          opacity: {
            light: 0.8,
            dark: 0.8,
          },
          isFront: false,
          ifOverflow: "hidden",
        },
        {
          label: {
            value: "Mixed",
            backgroundColor: {
              light: "#FFB366",
              dark: "#2A2A2A",
            },
            textColor: {
              light: "#CC7A00",
              dark: "#A0A0A0",
            },
          },
          x_axis_domain: (args) => {
            if (args.max_prev < 15) return { x1: -20, x2: 20, y1: 0, y2: _.clamp(args.max_prev + 10, 15) };
            return { x1: -20, x2: 20, y1: 0, y2: args.max_prev + 10 };
          },
          y_axis_domain: (args) => {
            if (args.max_prev < 15) return { x1: 0, x2: _.clamp(args.max_prev + 10, 15), y1: -20, y2: 20 };
            return { x1: 0, x2: args.max_prev + 10, y1: -20, y2: 20 };
          },
          fill: {
            light: "#FFD199",
            dark: "#1C1C1F",
          },
          opacity: {
            light: 0.8,
            dark: 0.8,
          },
          isFront: false,
          ifOverflow: "hidden",
        },
        {
          label: {
            value: "High Risk",
            backgroundColor: {
              light: "#FF3333",
              dark: "#4D0000",
            },
            textColor: {
              light: "#CC0000",
              dark: "#FF9999",
            },
          },
          x_axis_domain: (args) => {
            if (args.max_prev < 15) return undefined;
            return { x1: -100, x2: -20, y1: 15, y2: args.max_prev + 10 };
          },
          y_axis_domain: (args) => {
            if (args.max_prev < 15) return undefined;
            return { x1: 15, x2: args.max_prev + 10, y1: -20, y2: -100 };
          },
          fill: {
            light: "#FF5555",
            dark: "#6A0000",
          },
          opacity: {
            light: 0.8,
            dark: 0.8,
          },
          isFront: false,
          ifOverflow: "hidden",
        },
        {
          label: {
            value: "Low Risk",
            backgroundColor: {
              light: "#FF7777",
              dark: "#6B2424",
            },
            textColor: {
              light: "#CC3333",
              dark: "#FFB3B3",
            },
          },
          x_axis_domain: (args) => {
            if (args.max_prev < 15) return { x1: -100, x2: -20, y1: 0, y2: _.clamp(args.max_prev + 10, 15) };
            return { x1: -100, x2: -20, y1: 0, y2: 15 };
          },
          y_axis_domain: (args) => {
            if (args.max_prev < 15) return { x1: 0, x2: _.clamp(args.max_prev + 10, 15), y1: -20, y2: -100 };
            return { x1: 0, x2: 15, y1: -20, y2: -100 };
          },
          fill: {
            light: "#FF9999",
            dark: "#8B2E2E",
          },
          opacity: {
            light: 0.7,
            dark: 0.6,
          },
          isFront: false,
          ifOverflow: "hidden",
        },
      ],
    },
  },
};

export function createState(
  instance_id: string,
  props: ICreateStateProps,
): { local: ILocalState$; remote: IRemoteState$ } {
  const local: ILocalState$ = validateAndMergeState(
    {
      label: "Measure Correlation",
      ui_merged: false,
      config,
      views: {
        currentView: "analysis",
        analysis: {
          chart_config: {
            x_axis: {
              data_key: config.measure.measures[0].id,
              label: `${config.measure.measures[0].label} (${config.measure.measures[0].unit ?? ""})`,
              domain: config.measure.measures[0].domain,
              compactValues: config.measure.measures[0].compactYAxisValues,
              unit: config.measure.measures[0].unit,
            },
            y_axis: {
              data_key: config.measure.measures[1].id,
              label: `${config.measure.measures[1].label} (${config.measure.measures[1].unit ?? ""})`,
              domain: config.measure.measures[1].domain,
              compactValues: config.measure.measures[1].compactYAxisValues,
              unit: config.measure.measures[1].unit,
            },
          },
          toggles: {
            show_underlay: true,
          },
          selectors: {
            company: {
              selectedCompanyId: null,
            },
            x_axis_measure: {
              selectedMeasureId: "NSS",
            },
            y_axis_measure: {
              selectedMeasureId: "PREVALENCE",
            },
            geo: {
              selectedLocationId: undefined,
              selectedLocationType: undefined,
            },
          },
          filters: {
            topic: legend.features.filter.createObservableState(),
            timeframe: legend.features.filter.createObservableState(),
            applied_filters: {
              company_id: [],
              topic_name: [],
              timeframe: [],
            },
          },
        },
      },
    },
    props.local,
  );

  const remote: IRemoteState$ = {
    measures: {
      pending: false,
      error: false,
      data: legend.sync.synced({
        initial: [],
        get: async () => {
          const instance = rc.registry.actions.getInstance(instance_id);
          try {
            instance.remote.measures.pending.set(true);
            const selected_company_id = instance.local.views.analysis.selectors.company.selectedCompanyId.get();
            const company_measure_filter_to_measure_name_map =
              instance.local.config.company.measure_filter_to_measure_name_map.get();
            const measures = instance.local.config.measure.measures.get();
            const selected_x_measure_id =
              instance.local.views.analysis.selectors.x_axis_measure.selectedMeasureId.get();
            const selected_y_measure_id =
              instance.local.views.analysis.selectors.y_axis_measure.selectedMeasureId.get();

            const x_measure = measures.find((measure) => measure.id === selected_x_measure_id);
            const y_measure = measures.find((measure) => measure.id === selected_y_measure_id);

            const selected_location_id = instance.local.views.analysis.selectors.geo.selectedLocationId.get();
            const selected_location_type = instance.local.views.analysis.selectors.geo.selectedLocationType.get();

            if (!x_measure || !y_measure) {
              instance.remote.measures.pending.set(false);
              instance.remote.measures.error.set(true);
              return;
            }

            const x_measure_name = mapMeasureNameToParams(
              company_measure_filter_to_measure_name_map,
              x_measure.id,
              selected_location_id,
              selected_location_type,
            );
            const y_measure_name = mapMeasureNameToParams(
              company_measure_filter_to_measure_name_map,
              y_measure.id,
              selected_location_id,
              selected_location_type,
            );

            // Guard against no company selected - Data API will return an error 400 otherwise
            if (!selected_company_id) {
              return [];
            }

            let params: Parameters<IAPI["deltabase_data_api"]["measures"]["get"]>["0"] = {
              company_id: [selected_company_id],
              topic_name: instance.local.views.analysis.filters.applied_filters.topic_name.get(),
              year: instance.local.views.analysis.filters.applied_filters.timeframe.get(),
              measure_name: [x_measure_name, y_measure_name],
            };

            if (selected_location_id && selected_location_type) {
              if (selected_location_type === "continent") {
                params.continent = selected_location_id;
              } else if (selected_location_type === "country") {
                params.country = selected_location_id;
              }
            }

            const response = await api.deltabase_data_api.measures.get(params);

            // group by topic
            const grouped_data: Record<string, IMeasure[]> = {};
            response.data.forEach((measure) => {
              const topic = measure.topic_name;

              if (!grouped_data[topic]) {
                grouped_data[topic] = [];
              }
              grouped_data[topic].push(measure);
            });

            type Entry = { company_id: string; topic_name: string; x: number | null; y: number | null };
            const chart_data: Entry[] = [];

            Object.values(grouped_data).forEach((measures) => {
              let no_signal = false;
              const entry: Entry = {
                company_id: selected_company_id,
                topic_name: "",
                x: null,
                y: null,
              };
              measures.forEach((measure) => {
                entry.topic_name = measure.topic_name;
                no_signal = measure.measure_signal === "NO_SIGNAL";

                if (measure.measure_name === x_measure_name) {
                  entry.x = no_signal ? null : transformMeasureScore(measure, selected_x_measure_id);
                }

                if (measure.measure_name === y_measure_name) {
                  entry.y = no_signal ? null : transformMeasureScore(measure, selected_y_measure_id);
                }
              });
              if (isNil(entry.x) || isNil(entry.y)) {
                return;
              }
              chart_data.push(entry);
            });

            legend.state.batch(() => {
              instance.remote.measures.pending.set(false);
              instance.remote.measures.error.set(false);
            });
            return chart_data;
          } catch (error) {
            debugger;
            legend.state.batch(() => {
              instance.remote.measures.pending.set(false);
              instance.remote.measures.error.set(true);
            });
          }
        },
        waitFor: () => {
          const token = auth.state$.token.get();
          return !!token;
        },
      }),
    },
    evidence: {
      pending: false,
      error: false,
      data: legend.sync.synced({
        initial: {},
        get: async () => {
          const instance = rc.registry.actions.getInstance(instance_id);
          try {
            instance.remote.evidence.pending.set(true);
            const peerset = report_builder.remote.peerset.data.get();
            const selected_company_id = instance.local.views.analysis.selectors.company.selectedCompanyId.get();

            // Guard against no company selected - Data API will return an error 400 otherwise
            if (!selected_company_id) {
              return [];
            }

            const params: Parameters<IAPI["deltabase_data_api"]["content_extracts"]["get"]>["0"] = {
              company_id: [selected_company_id],
              topic_name: instance.local.views.analysis.filters.applied_filters.topic_name.get(),
              year: instance.local.views.analysis.filters.applied_filters.timeframe.get(),
              sentiment_type: [],
            };

            const response = await api.deltabase_data_api.content_extracts.get(params);

            const evidenceData: Record<string, Array<ITransformedContentExtract>> = {};
            params.topic_name?.forEach((topic_name) => {
              evidenceData[topic_name] = [];
            });

            response.data.forEach((extract) => {
              const { topic_name, company_id, text, sentiment_type } = extract;

              const company = peerset[company_id];

              const cleanedExtract: ITransformedContentExtract = {
                ...extract,
                text: text.replace(/['"]/g, ""),
                company: company ?? null,
              };

              const currentExtracts = evidenceData[topic_name];
              const hasBothSentiments =
                (params?.sentiment_type?.includes("Positive") && params?.sentiment_type?.includes("Negative")) ||
                params?.sentiment_type?.length === 0;

              if (hasBothSentiments) {
                // If we want both sentiments, ensure we get one positive and one negative
                if (sentiment_type === "Positive" && !currentExtracts.some((e) => e.sentiment_type === "Positive")) {
                  currentExtracts.unshift(cleanedExtract); // Add positive first
                } else if (
                  sentiment_type === "Negative" &&
                  !currentExtracts.some((e) => e.sentiment_type === "Negative")
                ) {
                  currentExtracts.push(cleanedExtract); // Add negative second
                }
              } else if (currentExtracts.length < 2) {
                // If not filtering by both sentiments, just add up to 2 extracts
                currentExtracts.push(cleanedExtract);
              }
            });

            // Sort topics alphabetically within each group
            const sortedTopics = Object.keys(evidenceData).sort();

            sortedTopics.forEach((topicName) => {
              evidenceData[topicName] = evidenceData[topicName];
            });

            legend.state.batch(() => {
              instance.remote.evidence.pending.set(false);
              instance.remote.evidence.error.set(false);
            });

            return evidenceData;
          } catch (error) {
            debugger;
            legend.state.batch(() => {
              instance.remote.evidence.pending.set(false);
              instance.remote.evidence.error.set(true);
            });
          }
        },
        waitFor: () => {
          const token = auth.state$.token.get();
          return !!token;
        },
      }),
    },
  };

  return { local, remote };
}

export const actions: IActions = {
  views: {
    analysis: {
      selectors: {
        x_axis_measure: {
          set: (instance_id: string, measure_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const measure = instance.local.config.measure.measures.get().find((m) => m.id === measure_id);
            if (!measure) return;

            legend.state.batch(() => {
              instance.local.views.analysis.selectors.x_axis_measure.selectedMeasureId.set(measure_id);
              instance.local.views.analysis.chart_config.x_axis.set({
                label: `${measure.label}  ${measure.unit ? `(${measure.unit})` : ""}`,
                domain: measure.domain,
                compactValues: measure.compactYAxisValues,
                unit: measure.unit,
              });
            });
          },
        },
        y_axis_measure: {
          set: (instance_id: string, measure_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const measure = instance.local.config.measure.measures.get().find((m) => m.id === measure_id);
            if (!measure) return;

            legend.state.batch(() => {
              instance.local.views.analysis.selectors.y_axis_measure.selectedMeasureId.set(measure_id);
              instance.local.views.analysis.chart_config.y_axis.set({
                label: `${measure.label}  ${measure.unit ? `(${measure.unit})` : ""}`,
                domain: measure.domain,
                compactValues: measure.compactYAxisValues,
                unit: measure.unit,
              });
            });
          },
        },
      },
      filters: {
        topic: {
          applyAll: (instance_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const applied: string[] = [];
            const filters = instance.local.views.analysis.filters.topic.data.get();
            Object.values(filters).forEach((filter) => {
              if (filter.checked) {
                applied.push(filter.id);
              }
            });
            instance.local.views.analysis.filters.applied_filters.topic_name.set(applied);
          },
          toggle: (instance_id: string, filter_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            legend.features.filter.toggle({
              state: instance.local.views.analysis.filters.topic,
              params: { id: filter_id },
            });
          },
          toggleGroup: (instance_id: string, filter_group: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            legend.features.filter.toggleGroup({
              state: instance.local.views.analysis.filters.topic,
              params: { group: filter_group },
            });
          },
          getFiltersByGroup: (instance_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const out = legend.features.filter.getFiltersByGroup({
              state: instance.local.views.analysis.filters.topic,
            });
            return out;
          },
        },
        timeframe: {
          applyAll: (instance_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const applied: string[] = [];
            const filters = instance.local.views.analysis.filters.timeframe.data.get();
            Object.values(filters).forEach((filter) => {
              if (filter.checked) {
                applied.push(filter.id);
              }
            });
            instance.local.views.analysis.filters.applied_filters.timeframe.set(applied);
          },
          toggle: (instance_id: string, filter_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            legend.features.filter.toggle({
              state: instance.local.views.analysis.filters.timeframe,
              params: { id: filter_id },
            });
          },
          toggleGroup: (instance_id: string, filter_group: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            legend.features.filter.toggleGroup({
              state: instance.local.views.analysis.filters.timeframe,
              params: { group: filter_group },
            });
          },
          toggleByRange: (instance_id, range) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const filters_obs = instance.local.views.analysis.filters.timeframe.data;
            const filters_arr = Object.values(filters_obs.get());
            const [startYear, endYear] = range.split("-");

            // For single year selection
            if (!endYear) {
              const targetFilter = filters_arr.find((filter) => filter.id === startYear);
              const newCheckedState = !targetFilter?.checked;

              filters_arr.forEach((filter) => {
                const checked = filter.id === startYear ? newCheckedState : false;
                filters_obs[filter.id].checked.set(checked);
              });
              return;
            }

            // For year range selection
            legend.state.batch(() => {
              // Check if all filters in range are already checked
              const allInRangeChecked = filters_arr.every((filter) => {
                const year = parseInt(filter.id);
                const inRange = year >= parseInt(startYear) && year <= parseInt(endYear);
                return !inRange || filter.checked;
              });

              // Toggle all filters in range based on current state
              // Get current checked state
              const currentCheckedFilters = filters_arr
                .filter((f) => f.checked)
                .map((f) => parseInt(f.id))
                .sort();

              // Get target range years
              const targetYears = Array.from(
                { length: parseInt(endYear) - parseInt(startYear) + 1 },
                (_, i) => parseInt(startYear) + i,
              ).sort();

              // Compare if current matches target range
              const isCurrentRange = JSON.stringify(currentCheckedFilters) === JSON.stringify(targetYears);

              filters_arr.forEach((filter) => {
                const year = parseInt(filter.id);
                const inRange = year >= parseInt(startYear) && year <= parseInt(endYear);
                // If current selection matches target range, toggle them all off
                // Otherwise set the new range
                const checked = isCurrentRange ? false : inRange;
                filters_obs[filter.id].checked.set(checked);
              });
            });
          },
          getFiltersByGroup: (instance_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const out = legend.features.filter.getFiltersByGroup({
              state: instance.local.views.analysis.filters.timeframe,
            });
            return out;
          },
        },
      },
    },
  },
};

export const observers: IObservers = {
  view: {
    analysis: {
      selectors: {
        company: {
          init: (instance_id: string) => {
            if (!instance_id) return;
            legend.state.observe<{ peerset_key: string }>((e) => {
              const instance = rc.registry.actions.getInstance(instance_id);
              const peerset = report_builder.remote.peerset.data.get();
              const current_peerset_key = JSON.stringify(Object.keys(peerset));

              if (!peerset || current_peerset_key.length === 0 || current_peerset_key === e.previous?.peerset_key) {
                return;
              }

              const company = Object.values(peerset)[0];

              if (!company) return;

              instance.local.views.analysis.selectors.company.selectedCompanyId.set(company.company_id);

              const peerset_key = JSON.stringify(Object.keys(peerset));

              return { peerset_key };
            });
          },
        },
      },
      filters: {
        topic: {
          init: (instance_id: string) => {
            if (!instance_id) return;
            legend.state.observe<{ initialised: boolean }>((e) => {
              if (e.previous?.initialised) return;
              const instance = rc.registry.actions.getInstance(instance_id);
              const topic_metadata = metadata.remote$.data.get().cached_transformed["topic"];

              if (!topic_metadata || topic_metadata.length === 0) return;

              // populate filter state
              legend.state.batch(() => {
                legend.features.filter.batchAddFilter({
                  state: instance.local.views.analysis.filters.topic,
                  params: {
                    data: topic_metadata.map((topic) => ({
                      id: topic.meta_data_value,
                      label: topic.meta_data_value,
                      checked: true,
                      disabled: false,
                      group: topic.meta_data_parent_value ?? "",
                    })),
                  },
                });
                actions.views.analysis.filters.topic.applyAll(instance_id);
              });

              return { initialised: true };
            });
          },
        },
        timeframe: {
          init: (instance_id: string) => {
            if (!instance_id) return;
            legend.state.observe<{ initialised: boolean }>((e) => {
              if (e.previous?.initialised) return;
              const instance = rc.registry.actions.getInstance(instance_id);
              const timeframe_metadata = metadata.remote$.data.get().cached_transformed["year"];
              const company_search_timeframe = report_builder.local.search.time_periods.get();

              if (!timeframe_metadata || timeframe_metadata.length === 0 || company_search_timeframe.length === 0) {
                return { initialised: false };
              }

              // populate filter state
              legend.state.batch(() => {
                legend.features.filter.batchAddFilter({
                  state: instance.local.views.analysis.filters.timeframe,
                  params: {
                    data: timeframe_metadata.map((timeframe) => {
                      const out = {
                        id: timeframe.meta_data_value,
                        label: timeframe.meta_data_value.toLowerCase(),
                        checked: company_search_timeframe?.includes(timeframe.meta_data_value) ?? false,
                        disabled: false,
                        group: "",
                      };
                      return out;
                    }),
                  },
                });
                actions.views.analysis.filters.timeframe.applyAll(instance_id);
              });

              return { initialised: true };
            });
          },
        },
      },
    },
  },
};
