import { Button } from "@/core/shadcn/ui/button";
import { Share, Users, Search, Copy, Check } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuLabel,
} from "@/core/shadcn/ui/dropdown-menu";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/core/shadcn/ui/dialog";
import { Input } from "@/core/shadcn/ui/input";
import { useState } from "react";
import { legend } from "@/core/utils/legend-state/core";
import { state } from "../../state/state";

export const ShareButton = legend.react.observer(() => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const shareLink = state.actions.share.getShareLink();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareLink);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000); // Reset after 2 seconds
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost">
            <Share className="h-4 w-4 mr-1" />
            Share Workspace
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuLabel>Share Options</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => setDialogOpen(true)}>
            <Users className="h-4 w-4 mr-1" />
            Share Peerset
          </DropdownMenuItem>
          {/* <DropdownMenuItem>
            <Search className="h-4 w-4 mr-1" />
            Share Search
          </DropdownMenuItem> */}
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent className="border-border">
          <DialogHeader>
            <DialogTitle>Share Peerset Link</DialogTitle>
          </DialogHeader>
          <div className="flex gap-2">
            <Input readOnly value={shareLink} className="flex-1" />
            <Button onClick={copyToClipboard} variant={copied ? "outline" : "default"}>
              {copied ? (
                <>
                  <Check className="h-4 w-4 mr-0" />
                  Copied!
                </>
              ) : (
                <>
                  <Copy className="h-4 w-4 mr-0" />
                  Copy
                </>
              )}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
});
