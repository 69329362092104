import "./main.css";
import { StrictMode, useEffect } from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { routeTree } from "./routeTree.gen";
import { env } from "./env";
import { auth } from "./core/state";

// Create a new router instance
const router = createRouter({ routeTree, context: { breadcrumbs: new Map() } });

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

// Sentry
Sentry.init({
  dsn: env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      // NOTE: This will disable built-in masking. Only use this if your site has no sensitive data, or if you've already set up other options for masking or blocking relevant data, such as 'ignore', 'block', 'mask' and 'maskFn'.
      maskAllText: false,
      blockAllMedia: false,
    }),
  ], // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0,
});

function ConnectSentryUserToAuth0({ children }: { children: React.ReactNode }) {
  const auth = useAuth0();
  useEffect(() => {
    if (auth.user && auth.isAuthenticated) {
      Sentry.setUser(auth.user);
    }
  }, [auth.user, auth.isAuthenticated]);
  return children;
}

// Auth0
function ConnectAuth0ToState({ children }: { children: React.ReactNode }) {
  const auth0 = useAuth0();

  useEffect(() => {
    async function setAuthState() {
      if (auth0.isAuthenticated) {
        const token = await auth0.getAccessTokenSilently();
        auth.state$.token.set(token);
        auth.state$.user.set(auth0.user ?? null);
      } else {
        auth.state$.token.set(null);
        auth.state$.user.set(null);
      }
    }
    setAuthState();
  }, [auth0]);

  return children;
}

// Render the app
const rootElement = document.getElementById("app")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <Auth0Provider
        domain={env.VITE_AUTH0_DOMAIN}
        clientId={env.VITE_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: env.VITE_AUTH0_REDIRECT_URI,
          audience: env.VITE_AUTH0_AUDIENCE,
        }}
      >
        <ConnectAuth0ToState>
          <ConnectSentryUserToAuth0>
            <RouterProvider router={router} />
          </ConnectSentryUserToAuth0>
        </ConnectAuth0ToState>
      </Auth0Provider>
    </StrictMode>,
  );
}
