import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/core/shadcn/utils";

const spinnerVariants = cva("border-2 rounded-full animate-spin", {
  variants: {
    variant: {
      default: "border-primary/40 border-t-primary",
      secondary: " border-muted-foreground/40 border-t-foreground/90",
      destructive: "border-destructive/40 border-t-destructive",
      ghost: "border-muted/0 border-t-foreground/90",
    },
    size: {
      default: "size-4",
      sm: "size-3",
      md: "size-4",
      lg: "size-6",
      xl: "size-8",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
});

interface LoadingSpinnerProps extends VariantProps<typeof spinnerVariants> {
  className?: string;
}

export function LoadingSpinner({ variant, size, className }: LoadingSpinnerProps) {
  return <div className={cn(spinnerVariants({ variant, size, className }))} />;
}
