import { Dialog, DialogClose, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/core/shadcn/ui/dialog";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/core/shadcn/ui/breadcrumb";
import { Input } from "@/core/shadcn/ui/input";
import { Button } from "@/core/shadcn/ui/button";
import { Checkbox } from "@/core/shadcn/ui/checkbox";
import { Group, X } from "lucide-react";
import { legend } from "@/core/utils/legend-state/core";
import { metadata } from "@/core/state";
import { ICommonFilter } from "@/core/state/types";
import { LoadingSpinner } from "@/core/components/loading-spinner";
import { state } from "../../state/state";
import { testIds } from "../../test-ids";

export const FilterTrigger = legend.react.observer(() => {
  return (
    <DialogTrigger asChild>
      <Button
        {...testIds.components.sectorFilter.trigger}
        variant="outline"
        className="flex items-center gap-2 bg-white shadow-sm hover:bg-accent/80 dark:bg-[#27272A]/25 dark:hover:bg-[#27272A]/45 relative"
      >
        <Group className="h-4 w-4" />
        <span>Sectors</span>
        <legend.react.Memo>
          {() => {
            const total = state.actions.filters.sector.getTotalAppliedFilters();
            if (total === 0) return null;
            return (
              <span className="absolute -top-[7px] -right-[6px] h-4 w-4 rounded-full bg-primary dark:bg-accent text-[8px] font-bold dark:text-foreground text-primary-foreground flex items-center justify-center">
                {total}
              </span>
            );
          }}
        </legend.react.Memo>
      </Button>
    </DialogTrigger>
  );
});

interface CheckboxItemProps {
  id: string;
  label: string;
  checked?: boolean;
  onCheckedChange?: (checked: boolean) => void;
}

const CheckboxItem = (props: CheckboxItemProps) => {
  return (
    <div
      {...testIds.components.sectorFilter.list.item}
      className="flex items-center space-x-2 rounded-[6px] bg-white hover:bg-accent/80 dark:bg-[#27272A]/25 dark:hover:bg-[#27272A]/10 border border-input px-3 py-2 text-sm font-medium text-foreground cursor-pointer"
      onClick={() => props.onCheckedChange?.(!props.checked)}
    >
      <Checkbox checked={props.checked} />
      <label className="cursor-pointer flex-1">{props.label}</label>
    </div>
  );
};

interface SelectedItemProps {
  id: string;
  label: string;
  onRemove: () => void;
}

const SelectedItem = (props: SelectedItemProps) => {
  return (
    <div
      {...testIds.components.sectorFilter.selected.item}
      onClick={props.onRemove}
      className="flex items-center space-x- rounded-[6px] cursor-pointer dark:bg-[#27272A]/25 dark:hover:bg-[#27272A]/10 border border-input pl-3 pr-2 py-2 text-sm font-medium text-foreground"
    >
      <span className="flex-1">{props.label}</span>
      <button
        {...testIds.components.sectorFilter.selected.removeButton}
        className="ml-2 rounded-sm dark:bg-accent/50 bg-white p-1 shadow-sm border border-border"
      >
        <X className="size-3" />
      </button>
    </div>
  );
};

export const SectorFilter = legend.react.observer(() => {
  const { all } = state.actions.filters.sector.getGroupedFilters();

  const internal_state = legend.react.useObservable({
    search: "",
    dialogOpen: false,
  });

  function handleApplyAllFilters() {
    state.actions.filters.sector.applyAll();
    internal_state.dialogOpen.set(false);
  }

  function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    internal_state.search.set(e.target.value);
  }

  function handleCheckedChange(filter: ICommonFilter) {
    state.actions.filters.sector.toggle(filter.id);
  }

  function filterBySearch(filter: ICommonFilter) {
    const s = internal_state.search.get().toLowerCase();
    const results = filter.label.toLowerCase().includes(s);
    return results;
  }

  function clearAll() {
    state.actions.filters.sector.clearAll();
  }

  function handleDialogOpenChange(open: boolean) {
    internal_state.dialogOpen.set(open);
  }

  const filters = all.all.filter(filterBySearch).sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Dialog open={internal_state.dialogOpen.get()} onOpenChange={handleDialogOpenChange}>
      <FilterTrigger />
      <DialogContent
        {...testIds.components.sectorFilter.dialog}
        className="max-w-4xl max-h-[calc(100vh-200px)] h-full flex flex-col p-0 border-input"
        hideCloseButton
      >
        <div
          {...testIds.components.sectorFilter.header}
          className="flex px-4 py-3 items-center gap-4 h-[42px] border-0 border-border border-b-1 bg-accent/60 dark:bg-accent/25 rounded-t-lg"
        >
          <Breadcrumb {...testIds.components.sectorFilter.breadcrumb}>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink className="text-muted-foreground">Filters</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink className="text-muted-foreground">Sectors</BreadcrumbLink>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <DialogClose {...testIds.components.sectorFilter.closeButton} className="ml-auto">
            <X className="h-4 w-4" />
          </DialogClose>
        </div>

        <div className="flex flex-col flex-1 overflow-hidden">
          <div className="flex-1 overflow-y-auto gap-4 flex flex-col px-4">
            <DialogTitle
              {...testIds.components.sectorFilter.title}
              className="text-xl font-semibold leading-[28px] text-foreground"
            >
              {metadata.remote$.data.cached_transformed["sector"].get()?.length} Industry Sectors
            </DialogTitle>

            {metadata.remote$.pending.get() ? (
              <div
                {...testIds.components.sectorFilter.loading}
                className="flex flex-col gap-4 pb-4 h-full w-full items-center justify-center"
              >
                <div className="flex items-center gap-2">
                  <LoadingSpinner className="size-4 animate-spin" />
                  <span>Loading...</span>
                </div>
              </div>
            ) : (
              <div {...testIds.components.sectorFilter.content} className="flex flex-col gap-4 pb-4">
                <Input
                  {...testIds.components.sectorFilter.search}
                  type="search"
                  placeholder="Search"
                  className="w-full"
                  value={internal_state.search.get()}
                  onChange={handleSearchChange}
                />

                <div {...testIds.components.sectorFilter.selected.container} className="flex flex-col gap-2">
                  <h4
                    {...testIds.components.sectorFilter.selected.heading}
                    className="text-xs font-semibold text-muted-foreground"
                  >
                    Selected Filters
                  </h4>
                  {all.checked.length > 0 ? (
                    <div {...testIds.components.sectorFilter.selected.list} className="flex flex-wrap gap-2 py-[5px]">
                      {all.checked.map((filter) => (
                        <SelectedItem
                          key={filter.id}
                          id={filter.id}
                          label={filter.label}
                          onRemove={() => handleCheckedChange(filter)}
                        />
                      ))}
                    </div>
                  ) : (
                    <div
                      {...testIds.components.sectorFilter.selected.empty}
                      className="flex flex-col gap-2 bg-accent/25 rounded-lg px-4 py-4 w-full"
                    >
                      <p className="text-xs text-muted-foreground">No filters selected</p>
                    </div>
                  )}
                </div>

                <div {...testIds.components.sectorFilter.list.container}>
                  <h4
                    {...testIds.components.sectorFilter.list.heading}
                    className="text-xs font-semibold text-muted-foreground mb-2"
                  >
                    Sectors
                  </h4>
                  {filters.length > 0 ? (
                    <div {...testIds.components.sectorFilter.list.grid} className="grid grid-cols-2 gap-2">
                      {filters.map((filter) => (
                        <CheckboxItem
                          key={filter.id}
                          label={filter.label}
                          id={filter.id}
                          checked={filter.checked}
                          onCheckedChange={() => handleCheckedChange(filter)}
                        />
                      ))}
                    </div>
                  ) : (
                    <div
                      {...testIds.components.sectorFilter.list.empty}
                      className="flex flex-col gap-2 bg-accent/25 rounded-lg px-4 py-4 w-full"
                    >
                      <p className="text-xs text-muted-foreground">No filters found</p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div
            {...testIds.components.sectorFilter.actions.container}
            className="flex border-t border-input pt-3 pb-[14px] px-4 items-center gap-2"
          >
            <Button
              {...testIds.components.sectorFilter.actions.clearButton}
              variant="outline"
              onClick={clearAll}
              disabled={all.checked.length === 0 || metadata.remote$.pending.get()}
            >
              Clear all
            </Button>
            <Button
              {...testIds.components.sectorFilter.actions.applyButton}
              className="hover:bg-[hsl(224.3,76.3%,42%)] bg-[hsl(224.3,76.3%,48%)] text-white"
              disabled={metadata.remote$.pending.get()}
              onClick={handleApplyAllFilters}
            >
              Apply filters ({state.actions.filters.sector.getTotalCheckedOrAppliedFilters()})
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
});
