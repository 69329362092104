interface DeltabaseLogoProps {
  className?: string;
}

export function DeltabaseLogo({ className }: DeltabaseLogoProps) {
  return (
    <svg width="43" height="50" viewBox="0 0 43 50" fill="none" className={className}>
      <path
        d="M33 25C33 17.8 27.2 12 20 12H16V5H18C29 5 38 14 38 25C38 36 29 45 18 45H16H6C5.4 45 5 44.6 5 44V12H2C0.9 12 0 12.9 0 14V15V46C0 48.2 1.8 50 4 50H18.4C32.2 50 42.9 38.8 43 25C43 11.1 32.9 0 19 0H13C11.9 0 11 0.9 11 2V36C11 37.1 11.9 38 13 38H20C27.2 38 33 32.2 33 25ZM28 25C28 29.4 24.4 33 20 33H17C16.4 33 16 32.6 16 32V18C16 17.4 16.4 17 17 17H19H20C24.4 17 28 20.6 28 25Z"
        fill="white"
      />
    </svg>
  );
}
