import { Collapsible } from "@/core/shadcn/ui/collapsible";
import { Button } from "@/core/shadcn/ui/button";
import { ChevronDown, Minus, Plus } from "lucide-react";
import { CollapsibleTrigger, CollapsibleContent } from "@/core/shadcn/ui/collapsible";
import {
  SidebarGroup,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuButton,
  SidebarMenuSub,
  SidebarMenuSubItem,
  SidebarMenuSubButton,
} from "@/core/shadcn/ui/sidebar";

// This is sample data.
const data = {
  navMain: [
    {
      title: "Section 1",
      url: "#",
      items: [
        {
          title: "What are the strengths and weaknesses of a Company's Culture?",
          url: "#",
          isActive: false,
        },
        {
          title: "Which companies lead and lag the peer set?",
          url: "#",
          isActive: false,
        },
        {
          title: "What are employees saying about the company?",
          url: "#",
          isActive: false,
        },
        {
          title: "What are the Culture Topic trends for my peer set?",
          url: "#",
          isActive: false,
        },
        {
          title: "How is a company's culture performing?",
          url: "#",
          isActive: false,
        },
        {
          title: "What are the trends across Culture Topics for a company?",
          url: "#",
          isActive: false,
        },
      ],
    },
  ],
};

export const ReportStructure = () => {
  return (
    <Collapsible
      defaultOpen
      className="px-4 py-4 w-full border-b border-border flex flex-col gap-[14px] group pb-6 data-[state=closed]:pb-4"
    >
      <div className="flex items-center justify-between gap-2 pt-0">
        <span className="text-sm font-medium">Report Structure</span>
        <CollapsibleTrigger asChild>
          <Button variant="outline" size="sm" className="p-1 h-auto w-auto">
            <ChevronDown className="h-4 w-4" />
          </Button>
        </CollapsibleTrigger>
      </div>
      <CollapsibleContent>
        <SidebarGroup className="p-0">
          <SidebarMenu>
            {data.navMain.map((item, index) => (
              <Collapsible key={item.title} defaultOpen className="group/collapsible">
                <SidebarMenuItem>
                  <CollapsibleTrigger asChild>
                    <SidebarMenuButton className="pointer-events-none">
                      {item.title} <Plus className="ml-auto group-data-[state=open]/collapsible:hidden" />
                      <Minus className="ml-auto group-data-[state=closed]/collapsible:hidden" />
                    </SidebarMenuButton>
                  </CollapsibleTrigger>
                  {item.items?.length ? (
                    <CollapsibleContent>
                      <SidebarMenuSub>
                        {item.items.map((item) => (
                          <SidebarMenuSubItem key={item.title} className="pointer-events-none">
                            <SidebarMenuSubButton asChild isActive={item.isActive} className="h-auto px-2 py-2">
                              <a href={item.url}>{item.title}</a>
                            </SidebarMenuSubButton>
                          </SidebarMenuSubItem>
                        ))}
                      </SidebarMenuSub>
                    </CollapsibleContent>
                  ) : null}
                </SidebarMenuItem>
              </Collapsible>
            ))}
          </SidebarMenu>
        </SidebarGroup>
      </CollapsibleContent>
    </Collapsible>
  );
};
