import { legend } from "../core";
import { Observable } from "@legendapp/state";
import { z } from "zod";

export const filterSchema = z.object({
  id: z.string(),
  label: z.string(),
  checked: z.boolean(),
  disabled: z.boolean().optional(),
  group: z.string().optional(),
});

const filterStatsSchema = z.object({
  totalChecked: z.number(),
  totalDisabled: z.number(),
});

export const filterStateSchema = z.object({
  stats: filterStatsSchema,
  data: z.record(filterSchema),
});

export type IFilter = z.infer<typeof filterSchema>;
export type IFilterStats = z.infer<typeof filterStatsSchema>;
export type IFilterState = z.infer<typeof filterStateSchema>;

export type IObservableFilterState = Observable<IFilterState>;

export function createObservableState(): IFilterState {
  return {
    stats: {
      totalChecked: 0,
      totalDisabled: 0,
    },
    data: {},
  };
}

interface IUpdateStatsProps {
  state: IObservableFilterState;
  options?: {};
}

function updateStats(props: IUpdateStatsProps) {
  const arr = Object.values(props.state.data.get());
  const totalChecked = arr.reduce((acc, curr) => {
    return acc + (curr.checked ? 1 : 0);
  }, 0);
  const totalDisabled = arr.reduce((acc, curr) => {
    return acc + (curr.disabled ? 1 : 0);
  }, 0);
  legend.state.batch(() => {
    props.state.stats.totalChecked.set(totalChecked);
    props.state.stats.totalDisabled.set(totalDisabled);
  });
}

interface IAddFilterProps {
  state: IObservableFilterState;
  params: {
    id: string;
    label: string;
    checked: boolean;
    disabled: boolean;
    group: string;
  };
  options?: {
    updateStats?: boolean;
  };
}

export function addFilter(props: IAddFilterProps) {
  const payload = {
    id: props.params.id,
    label: props.params.label,
    checked: props.params.checked ?? false,
    disabled: props.params.disabled ?? false,
    group: props.params.group ?? "",
  };

  if (props.options?.updateStats) {
    legend.state.batch(() => {
      props.state.data[props.params.id].set(payload);
      updateStats({ state: props.state });
    });
    return;
  }

  props.state.data[props.params.id].set(payload);
}

interface IBatchAddFilterProps {
  state: IObservableFilterState;
  params: {
    data: IAddFilterProps["params"][];
  };
}

export function batchAddFilter(props: IBatchAddFilterProps) {
  legend.state.batch(() => {
    props.params.data.forEach((filter) => {
      addFilter({ state: props.state, params: filter, options: { updateStats: false } });
    });
    updateStats({ state: props.state });
  });
}

interface IToggleProps {
  state: IObservableFilterState;
  params: {
    id: string;
  };
  options?: {
    maxChecked?: number;
  };
}

export function toggle(props: IToggleProps) {
  legend.state.batch(() => {
    if (props.state.data[props.params.id].checked.get()) {
      props.state.data[props.params.id].checked.set(false);
    } else {
      props.state.data[props.params.id].checked.set(true);
    }
    updateStats({ state: props.state });
  });
}

interface IToggleGroupProps {
  state: IObservableFilterState;
  params: {
    group: string;
  };
}

export function toggleGroup(props: IToggleGroupProps) {
  const filters = Object.values(props.state.data.get() ?? {}).filter((filter) => filter.group === props.params.group);
  const allChecked = filters.every((filter) => filter.checked);

  legend.state.batch(() => {
    if (allChecked) {
      filters.forEach((filter) => {
        props.state.data[filter.id].checked.set(false);
      });
    } else {
      filters.forEach((filter) => {
        props.state.data[filter.id].checked.set(true);
      });
    }
    updateStats({ state: props.state });
  });
}

interface IClearAllProps {
  state: IObservableFilterState;
  params?: {};
}

export function clearAll(props: IClearAllProps) {
  legend.state.batch(() => {
    const keys = Object.keys(props.state.data.get());
    keys.forEach((key) => {
      props.state.data[key].checked.set(false);
    });
    updateStats({ state: props.state });
  });
}

interface IGetFilterSplitsProps {
  params: {
    data: IFilter[];
  };
}

export interface IGetFilterSplitsResult {
  all: IFilter[];
  checked: IFilter[];
  nonChecked: IFilter[];
}

export function getFilterSplits(props: IGetFilterSplitsProps): IGetFilterSplitsResult {
  const checked = props.params?.data.filter((filter) => filter.checked) ?? [];
  const nonChecked = props.params?.data.filter((filter) => !filter.checked) ?? [];
  return {
    all: props.params.data,
    checked,
    nonChecked,
  };
}

interface IGetFiltersByGroupProps {
  state: IObservableFilterState;
  params?: {};
}

export interface IGetFiltersByGroupResult {
  all: IGetFilterSplitsResult;
  groups: Record<string, IGetFilterSplitsResult>;
}

export function getFiltersByGroup(props: IGetFiltersByGroupProps): IGetFiltersByGroupResult {
  const arr = Object.values(props.state.data.get() ?? {});
  const all = getFilterSplits({ params: { data: arr } });

  const groups = arr.reduce(
    (acc, curr) => {
      // if group exists, add to it
      const group = curr.group;
      if (group && acc[group]) {
        acc[group].push(curr);
      } else if (group) {
        acc[group] = [curr];
      }
      return acc;
    },
    {} as Record<string, IFilter[]>,
  );

  const groupsResult = Object.keys(groups).reduce(
    (acc, curr) => {
      acc[curr] = getFilterSplits({ params: { data: groups[curr] } });
      return acc;
    },
    {} as Record<string, IGetFilterSplitsResult>,
  );

  return {
    all,
    groups: groupsResult,
  };
}

interface IResetProps {
  state: IObservableFilterState;
  params?: {};
}

export function reset(props: IResetProps) {
  legend.state.batch(() => {
    props.state.data.set({});
    updateStats({ state: props.state });
  });
}
