import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { CompanySearchUI, companySearchState } from "@/features/company-search";
import { legend } from "@/core/utils/legend-state/core";

const searchSchema = z.object({
  search: z
    .object({
      company_ids: z.array(z.string()).optional(),
    })
    .optional(),
});

export const Route = createFileRoute("/")({
  validateSearch: searchSchema,
  component: withAuthenticationRequired(() => <CompanySearchUI />),
  beforeLoad: ({ search }) => {
    legend.state.batch(() => {
      if (search.search?.company_ids !== undefined) {
        companySearchState.local$.search.company_ids.set(search.search.company_ids);
      }
      // if (search.years !== undefined) {
      //   companySearch$.ui$.search.years.set(search.years);
      // }
      // if (search.sort_field !== undefined) {
      //   companySearch$.remote$.companies.params.sort_field.set(search.sort_field);
      // }
      // if (search.sort_direction !== undefined) {
      //   companySearch$.remote$.companies.params.sort_direction.set(search.sort_direction);
      // }
      // if (search.sectors !== undefined) {
      //   const sector = search.sectors.reduce(
      //     (acc, sector) => {
      //       acc[sector] = sector;
      //       return acc;
      //     },
      //     {} as Record<string, string>,
      //   );
      //   companySearch$.remote$.companies.params.sector.set(Object.values(sector));
      // }
      // if (search.company_name !== undefined) {
      //   companySearch$.remote$.companies.params.company_name.set(search.company_name);
      // }
    });
  },
});
