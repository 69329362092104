import { merge } from "lodash";
import { local_schema, ILocalState$ } from "./types";
import { IMeasure } from "@/core/shared/api";
import { transforms } from "@/core/shared/transforms";

export function validateAndMergeState(state: ILocalState$, new_state?: object) {
  if (new_state !== undefined) {
    const isValid = local_schema.safeParse(new_state);
    if (!isValid.success) {
      throw new Error(
        `Invalid props provided to createState$. Validation errors: ${JSON.stringify(isValid.error.format(), null, 2)}`,
      );
    }
    state = merge(state, new_state);
    return state;
  }
  return state;
}

export function transformMeasureScore(measure: IMeasure, measure_type: string) {
  if (measure_type === "NSS") {
    const score = transforms.nss.toPercentage(measure.measure_value);
    return score;
  } else if (measure_type === "PREVALENCE") {
    const score = transforms.prevalence.toPercentage(measure.measure_value);
    return score;
  } else if (measure_type === "RATING") {
    const score = transforms.rating.toNumber(measure.measure_value);
    return score;
  } else if (measure_type === "REVIEW_COUNT") {
    const score = transforms.count.toNumber(measure.measure_value);
    return score;
  }

  return null;
}
