import { legend } from "@/core/utils/legend-state/core";
import { Button } from "@/core/shadcn/ui/button";
import { ChevronDown, ChevronRight, Clock, Minus, Plus, Triangle } from "lucide-react";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/core/shadcn/ui/collapsible";
import { report_builder } from "@/features/report-builder/state";

export const GlobalFilters = legend.react.observer(() => {
  const peersetIds = report_builder.local.search.peerset_ids.get();
  const timePeriods = report_builder.local.search.time_periods.get();
  return (
    <Collapsible
      defaultOpen
      className="px-4 py-4 w-full border-b border-border flex flex-col data-[state=open]:gap-[14px] group pb-6 data-[state=closed]:pb-4"
    >
      <div className="flex items-center justify-between gap-2 pt-0">
        <span className="text-sm font-medium">Global Filters</span>
        <CollapsibleTrigger asChild>
          <Button variant="outline" size="sm" className="p-1 h-auto w-auto">
            <ChevronDown className="h-4 w-4" />
          </Button>
        </CollapsibleTrigger>
      </div>
      <CollapsibleContent className="flex flex-col gap-[14px]">
        <Button variant="outline" size="sm" className="w-full justify-start shadow-sm pointer-events-none">
          <Triangle className="h-4 w-4" />
          <div className="w-full flex justify-start">+{peersetIds.length} Companies</div>
          <div className="border-border border-l ml-2 pl-3 h-full flex items-center justify-center">
            <ChevronRight className="h-4 w-4" strokeWidth={2.5} />
          </div>
        </Button>
        <Button variant="outline" size="sm" className="w-full justify-start shadow-sm pointer-events-none">
          <Clock className="h-4 w-4" />
          <div className="w-full flex justify-start">+{timePeriods.length} Years</div>
          <div className="border-border border-l ml-2 pl-3 h-full flex items-center justify-center">
            <ChevronRight className="h-4 w-4" strokeWidth={2.5} />
          </div>
        </Button>
      </CollapsibleContent>
    </Collapsible>
  );
});
