import * as React from "react";

import { Button } from "@/core/shadcn/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/core/shadcn/ui/card";
import { Label } from "@/core/shadcn/ui/label";
import { Dialog, DialogTitle, DialogHeader, DialogContent, DialogTrigger } from "@/core/shadcn/ui/dialog";
import { testIds } from "../../test-ids";
import { env } from "@/env";
import { useSidebar } from "@/core/shadcn/ui/sidebar";

export function FeaturedCard() {
  const { open } = useSidebar();
  const email = env.VITE_REQUEST_EMAIL;
  const subject = encodeURIComponent("Deltabase new company data request");
  const body = encodeURIComponent(`Company: Sainsbury's
URL: sainsburys.co.uk
Description: Supermarket, online retail and banking group

Add more companies below using the above format.
`);

  const handleOnClickButton = () => {
    window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
  };

  if (!open) {
    return null;
  }

  return (
    <Card className="w-full shadow-none bg-card/45 border-border" {...testIds.components.featuredCard.container}>
      <CardHeader className="p-4 ">
        <CardTitle className="text-xs font-semibold" {...testIds.components.featuredCard.title}>
          Request Company
        </CardTitle>
        <CardDescription className="text-xs font-normal" {...testIds.components.featuredCard.description}>
          Click here to request a new company to be added to your data set.
        </CardDescription>
      </CardHeader>
      <CardFooter className="flex justify-between pb-[14px] px-4">
        <Dialog>
          <DialogTrigger asChild>
            <Button variant="link" size="sm" className="text-xs font-semibold px-0 py-0 h-[16px]">
              Click here
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle className="mb-3">Company Request Template</DialogTitle>
              <div
                {...testIds.components.featuredCard.emailInstructions}
                className="bg-muted dark:bg-accent/45 p-4 rounded-lg text-sm"
              >
                To request a company to be added to your data set, please email
                <a
                  {...testIds.components.featuredCard.emailLink}
                  href={`mailto:${email}?subject=${subject}&body=${body}`}
                  target="_top"
                  className="ml-1 underline"
                >
                  {email}
                </a>
                . Include the Company Name, Description and website URL. A member of the team will then reach out to
                confirm your order and next steps.
              </div>
            </DialogHeader>
            <div className="flex flex-col gap-4">
              <div className="space-y-2">
                <Label>Request Email</Label>
                <div className="flex items-center gap-2">
                  <div
                    {...testIds.components.featuredCard.emailDisplay}
                    className="flex-1 p-2 border rounded-md bg-background"
                  >
                    <span className="text-sm">{email}</span>
                  </div>
                  <Button onClick={handleOnClickButton} size="default">
                    Open in Mail
                  </Button>
                </div>
              </div>
              <div className="space-y-2">
                <Label>Example Email</Label>
                <div
                  {...testIds.components.featuredCard.exampleEmail.container}
                  className="p-3 border rounded-md space-y-2"
                >
                  <p {...testIds.components.featuredCard.exampleEmail.subject} className="text-sm font-medium">
                    Subject: Deltabase new company data request
                  </p>
                  <div className="space-y-1 text-sm">
                    <p {...testIds.components.featuredCard.exampleEmail.company}>
                      <span className="font-medium">Company:</span> Sainsbury's
                    </p>
                    <p {...testIds.components.featuredCard.exampleEmail.url}>
                      <span className="font-medium">URL:</span> sainsburys.co.uk
                    </p>
                    <p {...testIds.components.featuredCard.exampleEmail.description}>
                      <span className="font-medium">Description:</span> Supermarket, online retail and banking group
                    </p>
                  </div>
                  <p
                    {...testIds.components.featuredCard.exampleEmail.note}
                    className="text-xs italic text-muted-foreground"
                  >
                    Add more companies below using the above format.
                  </p>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </CardFooter>
    </Card>
  );
}
