import { Dialog, DialogClose, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/core/shadcn/ui/dialog";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/core/shadcn/ui/breadcrumb";
import { Input } from "@/core/shadcn/ui/input";
import { Button } from "@/core/shadcn/ui/button";
import { Checkbox } from "@/core/shadcn/ui/checkbox";
import { Clock, X } from "lucide-react";
import { legend } from "@/core/utils/legend-state/core";
import { config, state } from "../../state/state";
import { ICommonFilter } from "@/core/state/types";
import React from "react";
import { metadata } from "@/core/state";
import { LoadingSpinner } from "@/core/components/loading-spinner";
import { testIds } from "../../test-ids";

function groupConsecutiveYears(filters: ICommonFilter[]): { label: string; ids: string[] }[] {
  if (filters.length === 0) return [];

  // Sort filters by year
  const sorted = [...filters].sort((a, b) => parseInt(a.label) - parseInt(b.label));

  const groups: { label: string; ids: string[] }[] = [];
  let currentGroup = {
    start: parseInt(sorted[0].label),
    ids: [sorted[0].id],
  };

  for (let i = 1; i < sorted.length; i++) {
    const currentYear = parseInt(sorted[i].label);
    const prevYear = parseInt(sorted[i - 1].label);

    if (currentYear - prevYear === 1) {
      // Consecutive year, add to current group
      currentGroup.ids.push(sorted[i].id);
    } else {
      // Gap found, close current group and start new one
      groups.push({
        label: currentGroup.ids.length > 1 ? `${currentGroup.start}-${prevYear}` : `${currentGroup.start}`,
        ids: [...currentGroup.ids],
      });
      currentGroup = {
        start: currentYear,
        ids: [sorted[i].id],
      };
    }
  }

  // Add the last group
  groups.push({
    label:
      currentGroup.ids.length > 1
        ? `${currentGroup.start}-${parseInt(sorted[sorted.length - 1].label)}`
        : `${currentGroup.start}`,
    ids: currentGroup.ids,
  });

  return groups;
}

export const FilterTrigger = legend.react.observer(() => {
  return (
    <DialogTrigger asChild>
      <Button
        {...testIds.components.timeRangeFilter.trigger}
        variant="outline"
        className="flex items-center gap-2 bg-white shadow-sm hover:bg-accent/80 dark:bg-[#27272A]/25 dark:hover:bg-[#27272A]/45 relative"
      >
        <Clock className="h-4 w-4" />
        <span>Time Range</span>
        <legend.react.Memo>
          {() => {
            const { all } = state.actions.filters.years.getGroupedFilters();
            const total = all.applied.length;
            if (total === 0) return null;
            return (
              <span className="absolute -top-[7px] -right-[6px] h-4 w-4 rounded-full bg-primary dark:bg-accent text-[8px] font-bold dark:text-foreground text-primary-foreground flex items-center justify-center">
                {total}
              </span>
            );
          }}
        </legend.react.Memo>
      </Button>
    </DialogTrigger>
  );
});

interface CheckboxItemProps {
  id: string;
  label: string;
  checked?: boolean;
  onClick?: () => void;
  hideCheckbox?: boolean;
}

const CheckboxItem = (props: CheckboxItemProps) => {
  return (
    <div
      {...testIds.components.timeRangeFilter.yearFilters.item}
      className="flex items-center space-x-2 rounded-[6px] dark:bg-[#27272A]/25 dark:hover:bg-[#27272A]/10 border border-input px-3 py-2 text-sm font-medium text-foreground cursor-pointer"
      onClick={props.onClick}
    >
      {!props.hideCheckbox && <Checkbox checked={props.checked} />}
      <label className="cursor-pointer flex-1">{props.label}</label>
    </div>
  );
};

interface SelectedItemProps {
  id: string;
  label: string;
  onRemove: () => void;
}

const SelectedItem = (props: SelectedItemProps) => {
  return (
    <div
      {...testIds.components.timeRangeFilter.selected.item}
      onClick={props.onRemove}
      className="flex items-center space-x- rounded-[6px] cursor-pointer dark:bg-[#27272A]/25 dark:hover:bg-[#27272A]/10 border border-input pl-3 pr-2 py-2 text-sm font-medium text-foreground"
    >
      <span className="flex-1">{props.label}</span>
      <button
        {...testIds.components.timeRangeFilter.selected.removeButton}
        className="ml-2 rounded-sm dark:bg-accent/50 bg-white p-1 shadow-sm border border-border"
      >
        <X className="size-3" />
      </button>
    </div>
  );
};

export const TimeRangeFilter = legend.react.observer(() => {
  const { all, year } = state.actions.filters.years.getGroupedFilters();

  const internal_state = legend.react.useObservable({
    search: "",
    dialogOpen: false,
  });

  function handleApplyAllFilters() {
    state.actions.filters.years.applyAll();
    internal_state.dialogOpen.set(false);
  }

  function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    internal_state.search.set(e.target.value);
  }

  function handleCheckedChange(id: string, group?: string) {
    if (group === "quick-range") {
      state.actions.filters.years.toggleQuickRange(id);
    } else {
      state.actions.filters.years.toggle(id);
    }
  }

  function filterBySearch(searchTerm: string) {
    const s = internal_state.search.get().toLowerCase();
    const results = searchTerm.toLowerCase().includes(s);
    return results;
  }

  function clearAll() {
    state.actions.filters.years.clearAll();
  }

  function handleDialogOpenChange(open: boolean) {
    if (!open) {
      state.actions.filters.years.restore();
    }
    internal_state.dialogOpen.set(open);
  }

  const quickRanges = config.filters.years.quickRanges.getRanges().filter((range) => filterBySearch(range.label));
  const yearFilters = year.all.filter((filter) => filterBySearch(filter.label));

  return (
    <Dialog open={internal_state.dialogOpen.get()} onOpenChange={handleDialogOpenChange}>
      <FilterTrigger />
      <DialogContent
        {...testIds.components.timeRangeFilter.dialog}
        className="max-w-4xl max-h-[calc(100vh-200px)] h-full flex flex-col p-0 border-input"
        hideCloseButton
      >
        <div
          {...testIds.components.timeRangeFilter.header}
          className="flex px-4 py-3 items-center gap-4 h-[42px] border-0 border-border border-b-1 bg-accent/60 dark:bg-accent/25 rounded-t-lg"
        >
          <Breadcrumb {...testIds.components.timeRangeFilter.breadcrumb}>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink className="text-muted-foreground">Filters</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink className="text-muted-foreground">Time Range</BreadcrumbLink>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <DialogClose {...testIds.components.timeRangeFilter.closeButton} className="ml-auto">
            <X className="h-4 w-4" />
          </DialogClose>
        </div>

        <div className="flex flex-col flex-1 overflow-hidden">
          <div className="flex-1 overflow-y-auto gap-4 flex flex-col px-4">
            <DialogTitle
              {...testIds.components.timeRangeFilter.title}
              className="text-xl font-semibold leading-[28px] text-foreground"
            >
              Time Range
            </DialogTitle>

            {metadata.remote$.pending.get() ? (
              <div
                {...testIds.components.timeRangeFilter.loading}
                className="flex flex-col gap-4 pb-4 h-full w-full items-center justify-center"
              >
                <div className="flex items-center gap-2">
                  <LoadingSpinner className="size-4 animate-spin" />
                  <span>Loading...</span>
                </div>
              </div>
            ) : (
              <div {...testIds.components.timeRangeFilter.content} className="flex flex-col gap-4 pb-4">
                <Input
                  {...testIds.components.timeRangeFilter.search}
                  type="search"
                  placeholder="Search"
                  className="w-full"
                  value={internal_state.search.get()}
                  onChange={handleSearchChange}
                />

                <div {...testIds.components.timeRangeFilter.selected.container} className="flex flex-col gap-2">
                  <legend.react.Memo>
                    {() => {
                      const { all } = state.actions.filters.years.getGroupedFilters();
                      const total = all.checked.length;
                      return (
                        <h4
                          {...testIds.components.timeRangeFilter.selected.heading}
                          className="text-xs font-semibold text-muted-foreground"
                        >
                          Selected Filters ({total} years selected)
                        </h4>
                      );
                    }}
                  </legend.react.Memo>
                  {all.checked.length > 0 ? (
                    <div
                      {...testIds.components.timeRangeFilter.selected.list}
                      className="flex flex-wrap gap-2 py-[5px]"
                    >
                      {groupConsecutiveYears(all.checked).map((group) => (
                        <SelectedItem
                          key={group.ids.join("-")}
                          id={group.ids[0]}
                          label={group.label}
                          onRemove={() => {
                            group.ids.forEach((id) =>
                              handleCheckedChange(id, all.checked.find((f) => f.id === id)?.group),
                            );
                          }}
                        />
                      ))}
                    </div>
                  ) : (
                    <div
                      {...testIds.components.timeRangeFilter.selected.empty}
                      className="flex flex-col gap-2 bg-accent/25 rounded-lg px-4 py-4 w-full"
                    >
                      <p className="text-xs text-muted-foreground">No filters selected</p>
                    </div>
                  )}
                </div>

                <div {...testIds.components.timeRangeFilter.quickFilters.container} className="w-full">
                  <h4
                    {...testIds.components.timeRangeFilter.quickFilters.heading}
                    className="text-xs font-semibold text-muted-foreground mb-2"
                  >
                    Quick Options
                  </h4>
                  {quickRanges.length > 0 ? (
                    <div {...testIds.components.timeRangeFilter.quickFilters.grid} className="grid grid-cols-2 gap-2">
                      {quickRanges.map((quickRange) => (
                        <CheckboxItem
                          key={quickRange.id}
                          label={quickRange.label}
                          id={quickRange.id}
                          hideCheckbox
                          onClick={() => handleCheckedChange(quickRange.id, "quick-range")}
                        />
                      ))}
                    </div>
                  ) : (
                    <div
                      {...testIds.components.timeRangeFilter.quickFilters.empty}
                      className="flex flex-col gap-2 bg-accent/25 rounded-lg px-4 py-4 w-full"
                    >
                      <p className="text-xs text-muted-foreground">No filters found</p>
                    </div>
                  )}
                </div>

                <div {...testIds.components.timeRangeFilter.yearFilters.container}>
                  <h4
                    {...testIds.components.timeRangeFilter.yearFilters.heading}
                    className="text-xs font-semibold text-muted-foreground mb-2"
                  >
                    Individual Years
                  </h4>
                  {yearFilters.length > 0 ? (
                    <div {...testIds.components.timeRangeFilter.yearFilters.grid} className="grid grid-cols-2 gap-2">
                      {yearFilters.map((filter) => (
                        <CheckboxItem
                          key={filter.id}
                          label={filter.label}
                          id={filter.id}
                          checked={filter.checked}
                          onClick={() => handleCheckedChange(filter.id, filter.group)}
                        />
                      ))}
                    </div>
                  ) : (
                    <div
                      {...testIds.components.timeRangeFilter.yearFilters.empty}
                      className="flex flex-col gap-2 bg-accent/25 rounded-lg px-4 py-4 w-full"
                    >
                      <p className="text-xs text-muted-foreground">No filters found</p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div
            {...testIds.components.timeRangeFilter.actions.container}
            className="flex border-t border-input pt-3 pb-[14px] px-4 items-center gap-2"
          >
            <Button
              {...testIds.components.timeRangeFilter.actions.clearButton}
              variant="outline"
              onClick={clearAll}
              disabled={all.checked.length === 0 || metadata.remote$.pending.get()}
            >
              Clear all
            </Button>
            <legend.react.Memo>
              {() => {
                const { all } = state.actions.filters.years.getGroupedFilters();
                const total = all.checked.length;
                return (
                  <Button
                    {...testIds.components.timeRangeFilter.actions.applyButton}
                    className="hover:bg-[hsl(224.3,76.3%,42%)] bg-[hsl(224.3,76.3%,48%)] text-white"
                    disabled={metadata.remote$.pending.get() || total === 0}
                    onClick={handleApplyAllFilters}
                  >
                    Apply filters ({total})
                  </Button>
                );
              }}
            </legend.react.Memo>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
});
