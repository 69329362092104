import { legend } from "@/core/utils/legend-state/core";
import { ICommonFilterRecord } from "@/core/state/types";
import { auth } from "@/core/state/auth";
import { api, ICompany } from "@/core/shared/api";
import { transforms } from "@/core/shared/transforms";
import { metadata, IMetadata } from "@/core/state";
import { notify } from "@/core/notifications";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import { env } from "@/env";
import { IActions, ILocal, IObservers, IRemote, TransformedCompany } from "./types";

export const config = {
  filters: {
    years: {
      quickRanges: {
        getRanges: () => {
          return [config.filters.years.quickRanges.latestRange, config.filters.years.quickRanges.allYears];
        },
        latestRange: {
          id: "latest-range",
          label: "Select (2019-2024)",
          start: 2019,
          end: 2024,
          isInRange: (year: string) => {
            const start = config.filters.years.quickRanges.latestRange.start;
            const end = config.filters.years.quickRanges.latestRange.end;
            return parseInt(year) >= start && parseInt(year) <= end;
          },
        },
        allYears: {
          id: "all-years",
          label: "Select (2015-2024)",
          start: 2015,
          end: 2024,
          isInRange: (year: string) => {
            const start = config.filters.years.quickRanges.allYears.start;
            const end = config.filters.years.quickRanges.allYears.end;
            return parseInt(year) >= start && parseInt(year) <= end;
          },
        },
      },
    },
  },
};

export const local$ = legend.state.observable<ILocal>(
  legend.sync.synced({
    initial: {
      search: {
        company_ids: [],
        years: [],
      },
      tab: "companies",
      pagination: {
        data: {
          page_size: 20,
          total_pages: legend.state.linked({
            get: (): number => {
              return Math.ceil(local$.pagination.data.total_count.get() / local$.pagination.data.page_size.get());
            },
          }),
          current_page: 1,
          total_count: 0,
          page_start: 0,
          page_end: 20,
        },
      },
      peerset: {
        pending: false,
        maxPeersetSlots: 12,
        data: {},
      },
      filters: {
        sectors: legend.state.linked({
          get: () => {
            const sector_metadata = metadata.remote$.data.cached_transformed["sector"].get(true);
            const filters: ICommonFilterRecord = {};
            sector_metadata?.forEach((metadata) => {
              filters[metadata.meta_data_value] = {
                id: metadata.meta_data_value,
                label: metadata.meta_data_value,
                checked: false,
                applied: false,
                group: "sector-filters",
              };
            });
            const currentFilters = local$.filters.sectors.get() as ICommonFilterRecord;
            return { ...filters, ...currentFilters };
          },
        }),
        years: {},
      },
    },
    persist: {
      plugin: legend.local_storage.ObservablePersistSessionStorage,
      name: "company-search-ui",
    },
  }),
);

export const remote$ = legend.state.observable<IRemote>({
  companies: {
    pending: false,
    error: false,
    params: legend.sync.synced({
      initial: {
        years: [],
        company_name: "",
        sort_field: "company_name",
        sort_direction: "asc",
        sector: [],
      },
      persist: {
        plugin: legend.local_storage.ObservablePersistSessionStorage,
        name: "company-search-remote-params",
      },
    }),
    data: legend.sync.synced({
      initial: { raw_response: [], cached_transformed: [] },
      get: async () => {
        try {
          remote$.companies.pending.set(true);

          const params = {
            start_row: local$.pagination.data.page_start.get(),
            end_row: local$.pagination.data.page_end.get(),
            company_name: remote$.companies.params.company_name.get(),
            sort_field: remote$.companies.params.sort_field.get(),
            sort_direction: remote$.companies.params.sort_direction.get(),
            sector: remote$.companies.params.sector.get(),
            use_new_sectors: "true",
            year: remote$.companies.params.years.get(),
          };

          const locationMetadata = metadata.remote$.data.cached_transformed["country"].get(true);
          if (!locationMetadata || params.year.length === 0) return;

          const response = await api.deltabase_data_api.companies.get(params);

          const transformedData: Array<TransformedCompany> = response.data.map((company) => {
            const out = helpers.transformCompany(company, locationMetadata);
            return out;
          });
          legend.state.batch(() => {
            local$.pagination.data.total_count.set(response.count ?? 0);
            remote$.companies.pending.set(false);
          });
          return { raw_response: response.data, cached_transformed: transformedData };
        } catch (error) {
          legend.state.batch(() => {
            remote$.companies.pending.set(false);
            remote$.companies.error.set(true);
          });
        }
      },
      waitFor: () => {
        const token = auth.state$.token.get();
        return !!token;
      },
    }),
  },
});

// Actions
export const actions: IActions = {
  share: {
    getShareLink: () => {
      const searchParams = {
        search: JSON.stringify({
          company_ids: Object.keys(local$.peerset.data.get()),
        }),
      };
      const url = new URL(env.VITE_FRONTEND_BASE_URL);
      url.searchParams.set("search", searchParams.search);
      return url.toString();
    },
  },
  pagination: {
    increment: () => {
      legend.features.pagination.increment({
        state: local$.pagination,
      });

      // Find and reset scroll of results container
      const scrollContainer = document.getElementById("results-scroll-container");
      if (scrollContainer) {
        scrollContainer.scrollTop = 0;
      }
    },
    decrement: () => {
      legend.features.pagination.decrement({
        state: local$.pagination,
      });

      // Find and reset scroll of results container
      const scrollContainer = document.getElementById("results-scroll-container");
      if (scrollContainer) {
        scrollContainer.scrollTop = 0;
      }
    },
    decrementToStart: () => {
      legend.features.pagination.decrementToStart({
        state: local$.pagination,
      });

      // Find and reset scroll of results container
      const scrollContainer = document.getElementById("results-scroll-container");
      if (scrollContainer) {
        scrollContainer.scrollTop = 0;
      }
    },
    incrementToEnd: () => {
      legend.features.pagination.incrementToEnd({
        state: local$.pagination,
      });

      // Find and reset scroll of results container
      const scrollContainer = document.getElementById("results-scroll-container");
      if (scrollContainer) {
        scrollContainer.scrollTop = 0;
      }
    },
  },
  sort: {
    setSort: (field, direction) => {
      legend.state.batch(() => {
        remote$.companies.params.sort_field.set(field);
        remote$.companies.params.sort_direction.set(direction);
        actions.pagination.decrementToStart();
      });
    },
    setSortFromUI: (value) => {
      const map = actions.sort.mapSortFieldToRemote(value);

      if (!map?.field || !map?.direction) return;

      legend.state.batch(() => {
        remote$.companies.params.sort_field.set(map.field);
        remote$.companies.params.sort_direction.set(map.direction);
        actions.pagination.decrementToStart();
      });
    },
    mapSortFieldToRemote: (value) => {
      const field = value ? value : remote$.companies.params.sort_field.get();
      if (field === "company_name (A-Z)") {
        return { field: "company_name", direction: "asc" };
      } else if (field === "company_name (Z-A)") {
        return { field: "company_name", direction: "desc" };
      } else if (field === "nss-high-to-low") {
        return { field: "nss_per_company", direction: "desc" };
      } else if (field === "nss-low-to-high") {
        return { field: "nss_per_company", direction: "asc" };
      } else if (field === "review_count-high-to-low") {
        return { field: "count_per_company", direction: "desc" };
      } else if (field === "review_count-low-to-high") {
        return { field: "count_per_company", direction: "asc" };
      } else if (field === "rating-high-to-low") {
        return { field: "rating_per_company", direction: "desc" };
      } else if (field === "rating-low-to-high") {
        return { field: "rating_per_company", direction: "asc" };
      }
      return null;
    },
    mapSortFieldToUI: () => {
      const field = remote$.companies.params.sort_field.get();
      const direction = remote$.companies.params.sort_direction.get();
      switch (field) {
        case "company_name":
          return direction === "desc" ? "company_name (Z-A)" : "company_name (A-Z)";
        case "nss_per_company":
          return direction === "desc" ? "nss-high-to-low" : "nss-low-to-high";
        case "count_per_company":
          return direction === "desc" ? "review_count-high-to-low" : "review_count-low-to-high";
        case "rating_per_company":
          return direction === "desc" ? "rating-high-to-low" : "rating-low-to-high";
        default:
          return null;
      }
    },
  },
  filters: {
    resetAll: () => {
      legend.state.batch(() => {
        actions.filters.sector.reset();
        actions.filters.years.reset();
      });
    },
    sector: {
      toggle: (id) => {
        legend.state.batch(() => {
          if (local$.filters.sectors[id].checked.get()) {
            local$.filters.sectors[id].checked.set(false);
            local$.filters.sectors[id].applied.set(false);
          } else {
            local$.filters.sectors[id].checked.set(true);
          }
        });
      },
      clearAll: () => {
        for (const key in local$.filters.sectors.get()) {
          local$.filters.sectors[key].checked.set(false);
          local$.filters.sectors[key].applied.set(false);
        }
      },
      applyAll: () => {
        const sectors: string[] = [];
        legend.state.batch(() => {
          Object.values(local$.filters.sectors.get())
            .filter((filter) => filter.checked)
            .forEach((filter) => {
              sectors.push(filter.id);
              local$.filters.sectors[filter.id].applied.set(true);
            });
          remote$.companies.params.sector.set(sectors);
          actions.pagination.decrementToStart();
          local$.tab.set("companies");
        });
      },
      getGroupedFilters: () => {
        const v = Object.values(local$.filters.sectors.get());
        return {
          all: {
            all: v,
            checked: v.filter((filter) => filter.checked),
            nonChecked: v.filter((filter) => !filter.checked),
          },
        };
      },
      getTotalAppliedFilters: () => {
        return Object.values(local$.filters.sectors.get()).filter((filter) => filter.applied).length;
      },
      getTotalCheckedOrAppliedFilters: () => {
        return Object.values(local$.filters.sectors.get()).filter((filter) => filter.checked || filter.applied).length;
      },
      reset: () => {
        const sector_metadata = metadata.remote$.data.cached_transformed["sector"].get(true);
        const filters: ICommonFilterRecord = {};
        sector_metadata?.forEach((metadata) => {
          filters[metadata.meta_data_value] = {
            id: metadata.meta_data_value,
            label: metadata.meta_data_value,
            checked: false,
            applied: false,
            group: "sector-filters",
          };
        });
        local$.filters.sectors.set(filters);
        actions.filters.sector.applyAll();
      },
    },
    years: {
      toggle: (id) => {
        legend.state.batch(() => {
          if (local$.filters.years[id].checked.get()) {
            local$.filters.years[id].checked.set(false);
          } else {
            local$.filters.years[id].checked.set(true);
          }
        });
      },
      toggleQuickRange: (id) => {
        const filters = Object.values(local$.filters.years.get());
        legend.state.batch(() => {
          if (id === config.filters.years.quickRanges.latestRange.id) {
            const range = config.filters.years.quickRanges.latestRange;
            filters.forEach((filter) => {
              if (filter.group !== "quick-range") {
                local$.filters.years[filter.id].checked.set(range.isInRange(filter.id));
              }
            });
            return;
          } else if (id === config.filters.years.quickRanges.allYears.id) {
            const range = config.filters.years.quickRanges.allYears;
            filters.forEach((filter) => {
              if (filter.group !== "quick-range") {
                local$.filters.years[filter.id].checked.set(range.isInRange(filter.id));
              }
            });
            return;
          }
        });
      },
      applyAll: () => {
        const newFilters: ICommonFilterRecord = {};
        const oldFilters = Object.values(local$.filters.years.get());
        const year_metadata = metadata.remote$.data.cached_transformed["year"].get();

        oldFilters.forEach((filter) => {
          newFilters[filter.id] = {
            ...filter,
            applied: filter.checked,
          };
        });

        // Create a Set to store unique year values that will be applied as filters
        const years: Set<string> = new Set();

        // Process each filter that has been marked as "applied"
        Object.values(newFilters)
          .filter((filter) => filter.applied)
          .forEach((filter) => {
            // Handle special "quick-range" cases
            if (filter.group === "quick-range") {
              if (filter.id === "all-years") {
                // Add every available year from metadata to the Set
                const allYears = year_metadata.map((year) => year.meta_data_value);
                allYears.forEach((year) => {
                  years.add(year);
                });
              } else if (filter.id === "lastest-year") {
                // Find and add only the most recent year from metadata
                const maxYear = Math.max(...year_metadata.map((year) => parseInt(year.meta_data_value)));
                years.add(maxYear.toString());
              } else if (filter.id === "latest-range") {
                // Add the last 5 years, starting from previous year
                const currentYear = dayjs().year() - 1; // Start from previous year
                const minYear = currentYear - 5; // Go back 4 more years (5 total)
                const yearsInRange = year_metadata
                  .map((year) => parseInt(year.meta_data_value))
                  .filter((year) => year >= minYear && year <= currentYear);
                yearsInRange.forEach((year) => {
                  years.add(year.toString());
                });
              }
            } else {
              // Handle individual year selections
              // Find the year in metadata and add it if it exists
              const found = year_metadata.find((year) => year.meta_data_value === filter.id);
              if (!found) return;
              years.add(found.meta_data_value);
            }
          });

        legend.state.batch(() => {
          local$.filters.years.set(newFilters);
          remote$.companies.params.years.set(Array.from(years));
          actions.pagination.decrementToStart();
          local$.tab.set("companies");
        });
      },
      toggleByRange: (range) => {
        const [startYear, endYear] = range.split("-");
        const keys = Object.keys(local$.filters.years.get(true));
        legend.state.batch(() => {
          if (!endYear) {
            keys.forEach((key) => {
              local$.filters.years[key].checked.set(key === startYear);
            });
            return;
          }
          keys.forEach((key) => {
            local$.filters.years[key].checked.set(key >= startYear && key <= endYear);
          });
        });
      },
      clearAll: () => {
        for (const key in local$.filters.years.get()) {
          local$.filters.years[key].checked.set(false);
          local$.filters.years[key].applied.set(false);
        }
      },
      getGroupedFilters: () => {
        const v = Object.values(local$.filters.years.get() ?? {});
        const year = v.filter((filter) => filter.group === "year-filters");
        const quick = v.filter((filter) => filter.group === "quick-range");
        return {
          all: {
            checked: v.filter((filter) => filter.checked),
            nonChecked: v.filter((filter) => !filter.checked),
            applied: v.filter((filter) => filter.applied),
          },
          year: {
            all: year,
            checked: year.filter((filter) => filter.checked),
            nonChecked: year.filter((filter) => !filter.checked),
            applied: year.filter((filter) => filter.applied),
          },
        };
      },
      getTotalAppliedFilters: () => {
        return Object.values(local$.filters.years.get() ?? {}).filter((filter) => filter.applied).length;
      },
      getTotalCheckedOrAppliedFilters: () => {
        return Object.values(local$.filters.years.get() ?? {}).filter((filter) => filter.checked || filter.applied)
          .length;
      },
      reset: () => {
        const year_metadata = metadata.remote$.data.cached_transformed["year"].get(true);
        const filters: ICommonFilterRecord = {};
        year_metadata?.forEach((metadata) => {
          // const isSearchYear: boolean = searchYears.find((year) => year === metadata.meta_data_value) !== undefined;
          filters[metadata.meta_data_value] = {
            id: metadata.meta_data_value,
            label: metadata.meta_data_value,
            checked: config.filters.years.quickRanges.latestRange.isInRange(metadata.meta_data_value),
            applied: false,
            group: "year-filters",
          };
        });
        legend.state.batch(() => {
          local$.filters.years.set(filters);
          actions.filters.years.applyAll();
        });
      },
      restore: () => {
        const filters = Object.values(local$.filters.years.get() ?? {});

        legend.state.batch(() => {
          filters.forEach((filter) => {
            if (filter.applied) {
              local$.filters.years[filter.id].checked.set(true);
            } else {
              local$.filters.years[filter.id].checked.set(false);
            }
          });
        });
      },
    },
  },
  peerset: {
    add: (company, silent) => {
      const companyId = company.company_id;
      const peerset = local$.peerset.data.get();
      const maxPeersetSlots = local$.peerset.maxPeersetSlots.get();

      const peersetSlotCount = Object.keys(peerset).length;

      if (peersetSlotCount >= maxPeersetSlots && !silent) {
        // TODO: Error Toast notification
        notify.defaultError("You have reached the maximum number of companies in your peer set");
        return;
      }

      // Check if company already exists in any sector
      const exists = peerset[companyId];
      if (exists !== undefined && exists !== null && !silent) {
        notify.defaultMessage("Company already exists in peerset");
        return;
      }

      local$.peerset.data.assign({ [companyId]: company });
      if (!silent) {
        notify.companyMessage({ company, suffix: "added to peerset", color: "success" });
      }
    },
    remove: (companyId) => {
      const peerset = local$.peerset.data.get();
      const company = peerset[companyId];
      if (company) {
        local$.peerset.data[companyId].delete();
        notify.companyMessage({ company, suffix: "removed from peerset", color: "error" });
      }
    },
    clear: () => {
      local$.peerset.data.set({});
    },
    getIds: () => {
      return Object.values(local$.peerset.data.get(true)).map((company) => company.company_id);
    },
    getSize: () => {
      return Object.keys(local$.peerset.data.get(true)).length;
    },
    totalWarnings: () => {
      return Object.values(local$.peerset.data.get(true)).filter(
        (company) => company.count_per_company.strength === "low",
      ).length;
    },
  },
};

// Observers

// TODO:

// Observer changes to search params that can be populated via a URL. If populated prefetch the data. we do dont use a link as we want to prefetch the data once and not on every change.
const observers: IObservers = {
  syncSearchParams: () => {
    legend.state.observe(async () => {
      try {
        const token = auth.state$.token.get();
        const company_id = local$.search.company_ids.get();
        const locationMetadata = metadata.remote$.data.cached_transformed["country"].get(true);
        if (!token || company_id.length === 0 || !locationMetadata) return;

        local$.peerset.pending.set(true);

        const response = await api.deltabase_data_api.companies.get({
          company_id,
        });

        const transformedData = response.data.reduce(
          (acc, company) => {
            const transformed = helpers.transformCompany(company, locationMetadata);
            acc[company.company_id] = transformed;
            return acc;
          },
          {} as Record<string, TransformedCompany>,
        );

        legend.state.batch(() => {
          local$.peerset.data.set(transformedData);
          local$.peerset.pending.set(false);
        });
      } catch (error) {
        local$.peerset.pending.set(false);
        // TODO: Handle error - Log via Sentry
        Sentry.captureException(error);
      }
    });
  },
  filters: {
    years: {
      init: () => {
        legend.state.observe<{ init: boolean }>((e) => {
          const year_metadata = metadata.remote$.data.cached_transformed["year"].get();

          if (!year_metadata || e.previous?.init) return;

          const filters: ICommonFilterRecord = {};
          year_metadata?.forEach((metadata) => {
            // const isSearchYear: boolean = searchYears.find((year) => year === metadata.meta_data_value) !== undefined;
            filters[metadata.meta_data_value] = {
              id: metadata.meta_data_value,
              label: metadata.meta_data_value,
              checked: config.filters.years.quickRanges.latestRange.isInRange(metadata.meta_data_value),
              applied: false,
              group: "year-filters",
            };
          });
          const currentFilters = local$.filters.years.get() as ICommonFilterRecord;
          const allFilters = { ...filters, ...currentFilters };

          legend.state.batch(() => {
            local$.filters.years.set(allFilters);
            actions.filters.years.applyAll();
          });

          return { init: true };
        });
      },
    },
  },
};

// Helper functions
const helpers = {
  transformCompany: (company: ICompany, metadata: IMetadata.Metadata[]): TransformedCompany => {
    const location = metadata?.find((loc) => loc.meta_data_value === company.company_headquarters);
    return {
      ...company,
      nss_per_company: {
        percentage: transforms.nss.toPercentage(company.nss_per_company),
        strength: transforms.nss.toStrength(company.nss_per_company),
        sentiment: transforms.nss.toSentiment(company.nss_per_company),
        percentage_string: transforms.nss.toPercentageString(company.nss_per_company),
      },
      prevalence_per_company: {
        percentage: transforms.prevalence.toPercentage(company.prevalence_per_company),
        percentage_string: transforms.prevalence.toPercentageString(company.prevalence_per_company),
      },
      count_per_company: {
        count: transforms.count.toNumber(company.count_per_company),
        compact_number: transforms.count.toCompactNumber(company.count_per_company),
        strength: transforms.count.toStrength(company.signal_strength),
      },
      rating_per_company: {
        rating: transforms.rating.toNumber(company.rating_per_company),
      },
      sectors: {
        primary: company.sectors.filter((sector) => sector.sector_is_primary),
        secondary: company.sectors.filter((sector) => !sector.sector_is_primary),
      },
      company_headcount: {
        compact_number: transforms.headcount.toCompactNumber(company.company_headcount?.toString()),
      },
      company_headquarters: {
        id: location?.meta_data_value,
        label: location?.meta_data_description ?? location?.meta_data_value,
      },
    };
  },
};

// Start observers
observers.syncSearchParams();
observers.filters.years.init();
export const state = {
  local$,
  remote$,
  actions,
};
