/**
 * Creates a test ID by joining parts with hyphens for use in data-testid attributes
 * @param {string} feature - The feature or page name (e.g., "report", "dashboard")
 * @param {string} component - The component name (e.g., "options-menu", "table")
 * @param {string} [element] - Optional element identifier (e.g., "trigger", "button")
 * @returns {string} A hyphen-separated test ID combining all parts
 * @example
 * const testId = createTestId("report", "options-menu", "trigger");
 * // => "report-options-menu-trigger"
 *
 * const testId2 = createTestId("dashboard", "table");
 * // => "dashboard-table"
 */
export const createTestId = (feature: string, component: string, element?: string): { "data-testid": string } => {
  const parts = [feature, component];
  if (element) parts.push(element);
  return { "data-testid": parts.join("-") };
};
