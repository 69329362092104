import { Separator } from "@/core/shadcn/ui/separator";
import { SidebarTrigger } from "@/core/shadcn/ui/sidebar";
import {
  Breadcrumb,
  BreadcrumbSeparator,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
} from "@/core/shadcn/ui/breadcrumb";
import {
  SearchBar,
  BenchmarkButton,
  ShareButton,
  SectorFilter,
  ResetFiltersButton,
  ResultsTable,
  TimeRangeFilter,
} from "./components";
import { testIds } from "../test-ids";

export const CompanySearchUI = () => {
  return (
    <div className="" {...testIds.mainContent}>
      <header
        className="flex items-center gap-2 px-4 h-[64px] border-0 border-input w-full border-b-1"
        {...testIds.header}
      >
        <SidebarTrigger className="-ml-1" />
        <Separator orientation="vertical" className="mr-2 h-4" />
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbSeparator className="hidden md:block" />
            <BreadcrumbItem>
              <BreadcrumbPage>My Companies</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </header>

      <div className="flex flex-col p-4 gap-5" {...testIds.contentContainer}>
        <span className="font-semibold text-[24px] leading-[32px]" {...testIds.pageTitle}>
          My Companies
        </span>
        <div className="flex flex-col gap-[18px]" {...testIds.searchControls}>
          <div className="flex items-center gap-2" {...testIds.searchBarContainer}>
            <SearchBar />
            <BenchmarkButton />
          </div>
          <div className="flex items-center justify-between" {...testIds.filterContainer}>
            <div className="flex items-center gap-2" {...testIds.filterGroup}>
              <TimeRangeFilter />
              <SectorFilter />
              {/* <MoreFilters /> */}
              <ResetFiltersButton />
            </div>
            <ShareButton />
          </div>
        </div>
        <ResultsTable />
      </div>
    </div>
  );
};
