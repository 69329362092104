import { LogOut } from "lucide-react";
import { ChevronsUpDown } from "lucide-react";
import { SunMoon } from "lucide-react";
import { Moon } from "lucide-react";
import { Button } from "@/core/shadcn/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/core/shadcn/ui/dropdown-menu";
import { SidebarMenuButton, SidebarMenuItem } from "@/core/shadcn/ui/sidebar";
import { SidebarMenu } from "@/core/shadcn/ui/sidebar";
import { Sun } from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "@/core/shadcn/ui/avatar";
import { useTheme } from "@/core/shadcn/theme-provider";
import { auth } from "@/core/state";
import { legend } from "@/core/utils/legend-state/core";
import { acronym } from "@/core/utils/tailwind-toolkit/utils";
import { useAuth0 } from "@auth0/auth0-react";
import { env } from "@/env";

export const FooterMenu = legend.react.observer(() => {
  const auth0 = useAuth0();
  const user = auth.state$.user.get();
  const theme = useTheme();

  function handleLogout() {
    auth0.logout({
      logoutParams: {
        clientId: env.VITE_AUTH0_CLIENT_ID,
        returnTo: env.VITE_FRONTEND_BASE_URL,
      },
    });
  }

  function handleChangeTheme(type: "light" | "dark" | "system") {
    theme.setTheme(type);
  }

  function handleOpenLink(url: string) {
    window.open(url, "_blank");
  }

  return (
    <SidebarMenu>
      <SidebarMenuItem className="mb-2">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            >
              <Button variant="outline" size="icon" className="h-8 w-8 rounded-lg min-w-8">
                {theme.theme === "light" && (
                  <Sun className="h-[1.2rem] w-[1.2rem] text-sidebar-foreground rotate-0 scale-100 transition-all dark:-rotate-90" />
                )}
                {theme.theme === "dark" && (
                  <Moon className="h-[1.2rem] w-[1.2rem] text-sidebar-foreground rotate-0 scale-100 transition-all dark:-rotate-0" />
                )}
                {theme.theme === "system" && (
                  <SunMoon className="h-[1.2rem] w-[1.2rem] text-sidebar-foreground rotate-0 scale-100 transition-all dark:-rotate-90" />
                )}
                <span className="sr-only">Toggle theme</span>
              </Button>
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate font-semibold">Theme</span>
                <span className="truncate text-xs">{theme.theme}</span>
              </div>
              <ChevronsUpDown className="ml-auto size-4" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
            side="bottom"
            align="end"
            sideOffset={4}
          >
            <DropdownMenuItem onClick={() => handleChangeTheme("light")}>Light</DropdownMenuItem>
            <DropdownMenuItem onClick={() => handleChangeTheme("dark")}>Dark</DropdownMenuItem>
            <DropdownMenuItem onClick={() => handleChangeTheme("system")}>System</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>

      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            >
              <Avatar className="h-8 w-8 rounded-lg border border-sidebar-border">
                <AvatarImage src={user?.picture} alt={user?.name} />
                <AvatarFallback className="rounded-lg">{acronym(user?.name, 2)}</AvatarFallback>
              </Avatar>
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate font-semibold">{user?.name}</span>
                <span className="truncate text-xs">{user?.email}</span>
              </div>
              <ChevronsUpDown className="ml-auto size-4" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
            side="bottom"
            align="end"
            sideOffset={4}
          >
            <DropdownMenuLabel className="p-0 font-normal">
              <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                <Avatar className="h-8 w-8 rounded-lg">
                  <AvatarImage src={user?.picture} alt={user?.name} />
                  <AvatarFallback className="rounded-lg">{acronym(user?.name, 2)}</AvatarFallback>
                </Avatar>
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">{user?.name}</span>
                  <span className="truncate text-xs">{user?.email}</span>
                </div>
              </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={() => handleOpenLink(env.VITE_TERMS_AND_CONDITIONS)}>
              Terms & Conditions
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => handleOpenLink(env.VITE_PRIVACY_POLICY)}>Privacy Policy</DropdownMenuItem>
            <DropdownMenuItem onClick={() => handleOpenLink(env.VITE_COOKIE_POLICY)}>Cookie Policy</DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={handleLogout}>
              <LogOut />
              Log out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
});
