import { useSidebar } from "@/core/shadcn/ui/sidebar";
import { GlobalFilters } from "./global-filters";
import { PeersetSettings } from "./peerset-settings";
import { ReportStructure } from "./report-structure";
import { useMediaQuery } from "usehooks-ts";

export const ReportSettings = () => {
  const isLargeScreen = useMediaQuery("(max-width: 768px)");
  const { open } = useSidebar();

  if (open && !isLargeScreen) {
    return null;
  }
  return (
    <div className="h-full  flex-col border-r border-border w-full max-w-[300px] hidden lg:flex">
      <header className="sticky top-0 z-10 bg-rc-background flex flex-col px-4 py-4 border-b border-border text-base font-medium leading-normal">
        <span className="text-sm font-medium">Report Settings</span>
      </header>
      <div className="h-[calc(100vh-64px)] overflow-y-auto">
        <ReportStructure />
        <GlobalFilters />
        <PeersetSettings />
      </div>
    </div>
  );
};
