// Evidence filter should be driven by the analysis filters
// e.g company, topic, timeframe
// Sentiment shouldnt be a filter for evidence as it only shows 1 positive and 1 negative.

import { IContentExtract } from "@/core/shared/api";
import { filterStateSchema, IGetFiltersByGroupResult } from "@/core/utils/legend-state/feature/filter";
import { IPeersetCompany } from "@/features/report-builder/state/types";
import { z } from "zod";

const axis_schema = z.object({
  data_key: z.string().optional(),
  label: z.string().optional(),
  compactValues: z.boolean().optional(),
  domain: z
    .function()
    .args(
      z.object({
        PREV: z.object({
          min: z.number(),
          max: z.number(),
        }),
      }),
    )
    .returns(z.union([z.tuple([z.number(), z.number()]), z.tuple([z.string(), z.string()])]))
    .optional(),
  unit: z.string().optional(),
});

export const analysis_chart_config_schema = z.object({
  x_axis: axis_schema,
  y_axis: axis_schema,
});

export const local_schema = z.object({
  label: z.string(),
  ui_merged: z.boolean(),
  config: z.object({
    company: z.object({
      measure_filter_to_measure_name_map: z.record(
        z.object({
          default: z.string(),
          withGeoContinent: z.string(),
          withGeoCountry: z.string(),
        }),
      ),
      filters: z.object({
        hide: z.boolean(),
      }),
    }),
    measure: z.object({
      hide: z.boolean(),
      default: z.string(),
      measures: z.array(
        z.object({
          id: z.string(),
          label: z.string(),
          unit: z.string().optional(),
          acronym: z.string().optional(),
          showSign: z.boolean().optional(),
          domain: z
            .function()
            .args(
              z.object({
                PREV: z
                  .object({
                    min: z.number(),
                    max: z.number(),
                  })
                  .optional(),
              }),
            )
            .returns(z.tuple([z.number(), z.number()]))
            .optional(),
          compactYAxisValues: z.boolean().optional(),
          filter: z.object({
            applied: z.boolean(),
            disabled: z.boolean().optional(),
          }),
        }),
      ),
      reference_areas: z.record(
        z.string(),
        z.array(
          z.object({
            label: z.object({
              value: z.string(),
              backgroundColor: z.object({
                light: z.string(),
                dark: z.string(),
              }),
              textColor: z.object({
                light: z.string(),
                dark: z.string(),
              }),
            }),
            fill: z.object({
              light: z.string(),
              dark: z.string(),
            }),
            opacity: z.object({
              light: z.number(),
              dark: z.number(),
            }),
            isFront: z.boolean(),
            ifOverflow: z.enum(["hidden", "visible", "extendDomain", "discard"]),
            x_axis_domain: z
              .function()
              .args(z.object({ min_prev: z.number(), max_prev: z.number() }))
              .returns(
                z
                  .object({
                    x1: z.number(),
                    x2: z.number(),
                    y1: z.number(),
                    y2: z.number(),
                  })
                  .optional(),
              ),
            y_axis_domain: z
              .function()
              .args(z.object({ min_prev: z.number(), max_prev: z.number() }))
              .returns(
                z
                  .object({
                    x1: z.number(),
                    x2: z.number(),
                    y1: z.number(),
                    y2: z.number(),
                  })
                  .optional(),
              ),
          }),
        ),
      ),
    }),
  }),
  views: z.object({
    currentView: z.union([z.literal("analysis"), z.literal("alerts")]),
    analysis: z.object({
      chart_config: analysis_chart_config_schema,
      toggles: z.object({
        show_underlay: z.boolean(),
      }),
      selectors: z.object({
        company: z.object({
          selectedCompanyId: z.string().nullable(),
        }),
        x_axis_measure: z.object({
          selectedMeasureId: z.string(),
        }),
        y_axis_measure: z.object({
          selectedMeasureId: z.string(),
        }),
        geo: z.object({
          selectedLocationId: z.string().optional(),
          selectedLocationType: z.string().optional(),
        }),
      }),
      filters: z.object({
        topic: filterStateSchema,
        timeframe: filterStateSchema,
        applied_filters: z.object({
          company_id: z.array(z.string()).optional(),
          topic_name: z.array(z.string()).optional(),
          timeframe: z.array(z.string()).optional(),
        }),
      }),
    }),
  }),
});

export type ILocalState$ = z.infer<typeof local_schema>;

export interface IRemoteState$ {
  measures: {
    pending: boolean;
    error: boolean;
    data: { company_id: string; topic_name: string; x: number; y: number; z: number }[];
  };
  evidence: {
    pending: boolean;
    error: boolean;
    data: Record<string, Array<ITransformedContentExtract>>;
  };
}

export interface ICreateStateProps {
  local?: object;
}

export interface IActions {
  views: {
    analysis: {
      selectors: {
        x_axis_measure: {
          set: (instance_id: string, measure_id: string) => void;
        };
        y_axis_measure: {
          set: (instance_id: string, measure_id: string) => void;
        };
      };
      filters: {
        topic: {
          applyAll: (instance_id: string) => void;
          toggle: (instance_id: string, filter_id: string) => void;
          toggleGroup: (instance_id: string, filter_group: string) => void;
          getFiltersByGroup: (instance_id: string) => IGetFiltersByGroupResult;
        };
        timeframe: {
          applyAll: (instance_id: string) => void;
          toggle: (instance_id: string, filter_id: string) => void;
          toggleGroup: (instance_id: string, filter_group: string) => void;
          toggleByRange: (instance_id: string, range: `${number}-${number}`) => void;
          getFiltersByGroup: (instance_id: string) => IGetFiltersByGroupResult;
        };
      };
    };
  };
}

export interface IObservers {
  view: {
    analysis: {
      selectors: {
        company: {
          init: (instance_id: string) => void;
        };
      };
      filters: {
        topic: {
          init: (instance_id: string) => void;
        };
        timeframe: {
          init: (instance_id: string) => void;
        };
      };
    };
  };
}

export interface ITransformedContentExtract extends IContentExtract {
  company: IPeersetCompany | null;
}
