import JSZip from "jszip";

export const sanitizeForCSV = (value: string) => {
  if (!value) return "";
  // Escape special characters and wrap in quotes if needed
  const sanitized = value
    .replace(/[\n\r]+/g, " ") // Replace newlines with spaces
    .replace(/"/g, '""'); // Double-escape quotes
  // Wrap in quotes if the value contains commas, quotes, or spaces
  return /[,"\s]/.test(sanitized) ? `"${sanitized}"` : sanitized;
};

// Recursively create folders and files from payload
export const createZipStructure = (obj: any, currentFolder: JSZip) => {
  for (const [key, value] of Object.entries(obj)) {
    if (value && typeof value === "object") {
      const newFolder = currentFolder.folder(key);
      createZipStructure(value, newFolder!);
    } else {
      currentFolder.file(`${key}.${key}`, value as any);
    }
  }
};
