import { notify } from "@/core/notifications";
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "@/core/shadcn/ui/dropdown-menu";
import {
  SidebarGroup,
  SidebarMenuItem,
  SidebarMenuAction,
  SidebarMenuButton,
  SidebarGroupLabel,
  SidebarMenu,
} from "@/core/shadcn/ui/sidebar";
import { legend } from "@/core/utils/legend-state/core";
import { cn } from "@/core/shadcn/utils";
import { state$ } from "../../state/state";
import { Folder, Forward, MoreHorizontal, Trash } from "lucide-react";

export const MyReports = legend.react.observer(() => {
  const data = state$.local$.getMyReportsAsArray();
  return (
    <SidebarGroup className="group-data-[collapsible=icon]:hidden">
      <SidebarGroupLabel>My Reports</SidebarGroupLabel>
      <SidebarMenu>
        {data.map((item) => (
          <SidebarMenuItem key={item.title}>
            <SidebarMenuButton
              className={cn(item.disabled && "opacity-50 cursor-not-allowed")}
              onClick={() => {
                if (item.disabled) {
                  notify.defaultMessage(`${item.title} is currently under development`);
                  return;
                }
                // Handle normal navigation here
                window.location.href = item.url;
              }}
            >
              {item.icon && <item.icon />}
              <span>{item.title}</span>
            </SidebarMenuButton>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <SidebarMenuAction showOnHover>
                  <MoreHorizontal />
                  <span className="sr-only">More</span>
                </SidebarMenuAction>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-48 rounded-lg" side="bottom" align="end">
                <DropdownMenuItem
                  className={cn(item.disabled && "opacity-50 cursor-not-allowed")}
                  onClick={(e) => {
                    if (item.disabled) {
                      e.preventDefault();
                      notify.defaultMessage(`${item.title} is currently under development`);
                      return;
                    }
                  }}
                >
                  <Folder className="text-muted-foreground" />
                  <span>View Project</span>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Forward className="text-muted-foreground" />
                  <span>Share Project</span>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem>
                  <Trash className="text-muted-foreground" />
                  <span>Delete Project</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </SidebarMenuItem>
        ))}
        <SidebarMenuItem>
          <SidebarMenuButton className="text-sidebar-foreground/70">
            <MoreHorizontal className="text-sidebar-foreground/70" />
            <span>More</span>
          </SidebarMenuButton>
        </SidebarMenuItem>
      </SidebarMenu>
    </SidebarGroup>
  );
});
