import { useEffect, useState } from "react";
import { useDebounceValue } from "usehooks-ts";
import { Input } from "@/core/shadcn/ui/input";
import { state } from "../../state/state";
import { legend } from "@/core/utils/legend-state/core";
import { Search } from "lucide-react";
import { testIds } from "../../test-ids";

export const SearchBar = legend.react.observer(() => {
  const [value, setValue] = useState(state.remote$.companies.params.company_name.get() ?? "");
  const [bounceValue] = useDebounceValue(value, 500);

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
  }

  useEffect(() => {
    legend.state.batch(() => {
      state.remote$.companies.params.company_name.set(bounceValue);
      state.actions.pagination.decrementToStart();
      state.local$.tab.set("companies");
    });
  }, [bounceValue]);

  return (
    <div className="relative w-full" {...testIds.components.searchBar.container}>
      <div className="relative" {...testIds.components.searchBar.inputWrapper}>
        <Search
          className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground"
          {...testIds.components.searchBar.searchIcon}
        />
        <Input
          type="text"
          value={value}
          placeholder="Search by company name..."
          onChange={handleOnChange}
          className="pl-9"
          {...testIds.components.searchBar.input}
        />
      </div>
    </div>
  );
});
