import { createTestId } from "@/core/utils/test-ids";

export const testIds = {
  components: {
    featuredCard: {
      container: createTestId("featured-card", "container"),
      title: createTestId("featured-card", "title"),
      description: createTestId("featured-card", "description"),
      emailInstructions: createTestId("featured-card", "email-instructions"),
      emailLink: createTestId("featured-card", "email-link"),
      emailDisplay: createTestId("featured-card", "email-display"),
      exampleEmail: {
        container: createTestId("featured-card", "example-email"),
        subject: createTestId("featured-card", "example-subject"),
        company: createTestId("featured-card", "example-company"),
        url: createTestId("featured-card", "example-url"),
        description: createTestId("featured-card", "example-description"),
        note: createTestId("featured-card", "example-note"),
      },
    },
  },
} as const;
