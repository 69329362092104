import { IContentExtract } from "@/core/shared/api/types";
import { filterStateSchema, IGetFiltersByGroupResult } from "@/core/utils/legend-state/feature/filter";
import { z } from "zod";
import { IPeersetCompany } from "@/features/report-builder/state/types";

const config = z.object({
  company: z.object({
    measure_id_to_measure_name_map: z.record(z.string()),
    filters: z.object({
      hide: z.boolean(),
    }),
  }),
  measure: z.object({
    hide: z.boolean(),
    default: z.string(),
    measures: z.array(
      z.object({
        id: z.string(),
        label: z.string(),
        unit: z.string().optional(),
        acronym: z.string().optional(),
        showSign: z.boolean().optional(),
        domain: z.tuple([z.number(), z.number()]).optional(),
        compactYAxisValues: z.boolean().optional(),
        filter: z.object({
          checked: z.boolean(),
          disabled: z.boolean().optional(),
        }),
      }),
    ),
  }),
  benchmark: z.object({
    hide: z.boolean(),
    benchmarks: z.array(
      z.object({
        id: z.string(),
        label: z.string(),
        measure_id_to_measure_name_map: z.record(z.string()),
        filter: z.object({
          id: z.string().optional(),
          checked: z.boolean(),
          disabled: z.boolean(),
          label: z.string().optional(),
        }),
      }),
    ),
  }),
});

const axis_schema = z.object({
  data_key: z.string().optional(),
  label: z.string().optional(),
  compactValues: z.boolean().optional(),
  domain: z.tuple([z.number(), z.number()]).optional(),
  unit: z.string().optional(),
});

export const local_schema = z.object({
  label: z.string(),
  ui_merged: z.boolean(),
  config,
  views: z.object({
    currentView: z.union([z.literal("analysis"), z.literal("alerts")]),
    analysis: z.object({
      chart_config: z.object({
        entities: z.record(
          z.string(),
          z.object({
            id: z.string(),
            label: z.string(),
            color: z.string(),
          }),
        ),
        polar_axis: axis_schema,
      }),
      selectors: z.object({
        measure: z.object({
          selectedMeasureId: z.string(),
        }),
      }),
      filters: z.object({
        company: filterStateSchema,
        topic: filterStateSchema,
        timeframe: filterStateSchema,
        benchmark: filterStateSchema,
        applied_filters: z.object({
          company_id: z.array(z.string()).optional(),
          topic_name: z.array(z.string()).optional(),
          timeframe: z.array(z.string()).optional(),
          benchmarks: z.array(z.string()).optional(),
        }),
      }),
    }),
  }),
});

export type ILocalState$ = z.infer<typeof local_schema>;

export type IRemoteState$ = {
  measures: {
    pending: boolean;
    error: boolean;
    data: Record<string, number | string | null>[];
  };
  evidence: {
    pending: boolean;
    error: boolean;
    data: Record<string, Record<string, Record<string, ITransformedContentExtract[]>>>;
  };
};

export interface ICreateStateProps {
  local?: object;
}

export interface IActions {
  views: {
    analysis: {
      selectors: {
        measure: {
          set: (instance_id: string, measure_id: string) => void;
        };
      };
      filters: {
        benchmark: {
          applyAll: (instance_id: string) => void;
          toggle: (instance_id: string, filter_id: string) => void;
          toggleGroup: (instance_id: string, filter_group: string) => void;
          getFiltersByGroup: (instance_id: string) => IGetFiltersByGroupResult;
        };
        company: {
          applyAll: (instance_id: string) => void;
          toggle: (instance_id: string, filter_id: string) => void;
          toggleGroup: (instance_id: string, filter_group: string) => void;
          getFiltersByGroup: (instance_id: string) => IGetFiltersByGroupResult;
        };
        topic: {
          applyAll: (instance_id: string) => void;
          toggle: (instance_id: string, filter_id: string) => void;
          toggleGroup: (instance_id: string, filter_group: string) => void;
          getFiltersByGroup: (instance_id: string) => IGetFiltersByGroupResult;
        };
        timeframe: {
          applyAll: (instance_id: string) => void;
          toggle: (instance_id: string, filter_id: string) => void;
          toggleGroup: (instance_id: string, filter_group: string) => void;
          toggleByRange: (instance_id: string, range: `${number}-${number}`) => void;
          getFiltersByGroup: (instance_id: string) => IGetFiltersByGroupResult;
        };
      };
    };
  };
}

export interface IObservers {
  view: {
    analysis: {
      chart_config: {
        sync_to_state: (instance_id: string) => void;
      };
      filters: {
        benchmark: {
          init: (instance_id: string) => void;
        };
        company: {
          init: (instance_id: string) => void;
        };
        topic: {
          init: (instance_id: string) => void;
        };
        timeframe: {
          init: (instance_id: string) => void;
        };
      };
    };
  };
}

export interface ITransformedContentExtract extends IContentExtract {
  company: IPeersetCompany | null;
}
