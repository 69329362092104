import { User } from "@auth0/auth0-react";
import { observable } from "@legendapp/state";

interface IState {
  token: string | null;
  user: User | null;
}

export const state$ = observable<IState>({
  token: null,
  user: null,
});

export const auth = {
  state$,
};
