// Helpers

import { merge } from "lodash";
import {
  local_schema,
  ILocalState$,
  IRemoteState$,
  ICreateStateProps,
  IObservers,
  IActions,
  ITransformedContentExtract,
} from "./types";
import { api, IAPI, IContentExtract, IMeasure } from "@/core/shared/api";
import { transforms } from "@/core/shared/transforms";
import { z } from "zod";
import { legend } from "@/core/utils/legend-state/core";
import { rc } from "../../rc";
import { auth } from "@/core/state/auth";
import { metadata } from "@/core/state/metadata";
import { report_builder } from "@/features/report-builder/state";
import { IFilter } from "@/core/utils/legend-state/feature/filter";

// Config
const config: z.infer<typeof local_schema>["config"] = {
  company: {
    measure_id_to_measure_name_map: {
      NSS: "NSS_PER_COMPANY_PER_TOPIC",
      PREVALENCE: "PREVALENCE_PER_COMPANY_PER_TOPIC",
      RATING: "RATING_PER_COMPANY_PER_TOPIC",
      REVIEW_COUNT: "COUNT_PER_COMPANY_PER_TOPIC",
    },
    filters: {
      hide: false,
    },
  },
  measure: {
    hide: false,
    default: "NSS",
    measures: [
      {
        id: "NSS",
        label: "Net Promoter Score",
        unit: "%",
        acronym: "NSS",
        showSign: true,
        domain: [-100, 100],
        filter: {
          checked: true,
          disabled: false,
        },
      },
      {
        id: "PREVALENCE",
        label: "Topic Frequency ",
        unit: "%",
        acronym: "PREV",
        domain: [0, 100],
        filter: {
          checked: true,
          disabled: false,
        },
      },
      {
        id: "RATING",
        label: "Employee Rating",
        domain: [0, 5],
        filter: {
          checked: true,
          disabled: false,
        },
      },
      {
        id: "REVIEW_COUNT",
        label: "Employee Review Count",
        domain: undefined,
        compactYAxisValues: true,
        filter: {
          checked: true,
          disabled: false,
        },
      },
    ],
  },
  sentiment: {
    hide: false,
    sentiments: [
      {
        id: "Positive",
        label: "Positive",
        filter: {
          checked: true,
          disabled: false,
        },
      },
      {
        id: "Negative",
        label: "Negative",
        filter: {
          checked: true,
          disabled: false,
        },
      },
    ],
  },
};

export function createState(
  instance_id: string,
  props: ICreateStateProps,
): { local: ILocalState$; remote: IRemoteState$ } {
  const local = helpers.validateAndMergeState(
    {
      label: "Employee Voice: Company Personality",
      ui_merged: false,
      config,
      views: {
        currentView: "analysis",
        analysis: {
          filters: {
            company: legend.features.filter.createObservableState(),
            topic: legend.features.filter.createObservableState(),
            timeframe: legend.features.filter.createObservableState(),
            sentiment: legend.features.filter.createObservableState(),
            applied_filters: {
              company_id: [],
              topic_name: [],
              timeframe: [],
              sentiment: [],
            },
          },
        },
      },
    },
    props.local,
  );

  const remote: IRemoteState$ = {
    evidence: {
      pending: false,
      error: false,
      data: legend.sync.synced({
        initial: {},
        get: async () => {
          const instance = rc.registry.actions.getInstance(instance_id);
          try {
            instance.remote.evidence.pending.set(true);
            const topic_metadata = metadata.remote$.data.cached_transformed["topic"].get();
            const peerset = report_builder.remote.peerset.data.get();
            const sentiment_type = instance.local.views.analysis.filters.applied_filters.sentiment.get();

            const params: Parameters<IAPI["deltabase_data_api"]["content_extracts"]["get"]>["0"] = {
              company_id: instance.local.views.analysis.filters.applied_filters.company_id.get(),
              topic_name: instance.local.views.analysis.filters.applied_filters.topic_name.get(),
              year: instance.local.views.analysis.filters.applied_filters.timeframe.get(),
              sentiment_type:
                sentiment_type?.includes("Positive") && sentiment_type?.includes("Negative") ? [] : sentiment_type,
            };

            if (params.company_id?.length === 0) return {};

            const response = await api.deltabase_data_api.content_extracts.get(params);

            const evidenceData: Record<string, Record<string, Record<string, Array<ITransformedContentExtract>>>> = {};

            response.data.forEach((extract) => {
              const { topic_name, company_id, text, sentiment_type } = extract;
              const topic = topic_metadata.find((t) => t.meta_data_value === topic_name);

              const group = topic?.meta_data_parent_value ?? topic_name;
              if (!group) return;

              // Initialize nested structure
              if (!evidenceData[group]) evidenceData[group] = {};
              if (!evidenceData[group][topic_name]) evidenceData[group][topic_name] = {};
              if (!evidenceData[group][topic_name][company_id]) evidenceData[group][topic_name][company_id] = [];

              const company = peerset[company_id];

              const cleanedExtract: ITransformedContentExtract = {
                ...extract,
                text: text.replace(/['"]/g, ""),
                company: company ?? null,
              };

              const currentExtracts = evidenceData[group][topic_name][company_id];
              // Add all positives first, then negatives
              if (sentiment_type === "Positive") {
                currentExtracts.unshift(cleanedExtract); // Add positive to beginning
              } else if (sentiment_type === "Negative") {
                currentExtracts.push(cleanedExtract); // Add negative to end
              }
            });

            // Sort topics alphabetically within each group
            for (const group in evidenceData) {
              const sortedTopics = Object.keys(evidenceData[group]).sort();
              const sortedGroupData: Record<string, Record<string, Array<ITransformedContentExtract>>> = {};

              sortedTopics.forEach((topicName) => {
                sortedGroupData[topicName] = evidenceData[group][topicName];
              });

              evidenceData[group] = sortedGroupData;
            }

            legend.state.batch(() => {
              instance.remote.evidence.pending.set(false);
              instance.remote.evidence.error.set(false);
            });
            return evidenceData;
          } catch (error) {
            debugger;
            legend.state.batch(() => {
              instance.remote.evidence.error.set(true);
            });
          } finally {
            instance.remote.evidence.pending.set(false);
          }
        },
        waitFor: () => {
          const token = auth.state$.token.get();
          return !!token;
        },
      }),
    },
  };

  return { local, remote };
}

export const actions: IActions = {
  views: {
    analysis: {
      filters: {
        sentiment: {
          applyAll: (instance_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const filters = instance.local.views.analysis.filters.sentiment.data.get();

            const sentiments: string[] = [];
            Object.values(filters).forEach((filter) => {
              if (filter.checked) {
                const sentiment = instance.local.config.sentiment.sentiments.get().find((s) => s.id === filter.id);
                if (sentiment?.id === "Positive" || sentiment?.id === "Negative") {
                  sentiments.push(sentiment.id);
                }
              }
            });
            instance.local.views.analysis.filters.applied_filters.sentiment.set(sentiments);
          },
          toggle: (instance_id: string, filter_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            legend.features.filter.toggle({
              state: instance.local.views.analysis.filters.sentiment,
              params: { id: filter_id },
            });
          },
          toggleGroup: (instance_id: string, filter_group: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            legend.features.filter.toggleGroup({
              state: instance.local.views.analysis.filters.sentiment,
              params: { group: filter_group },
            });
          },
          getFiltersByGroup: (instance_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const out = legend.features.filter.getFiltersByGroup({
              state: instance.local.views.analysis.filters.sentiment,
            });
            return out;
          },
        },
        company: {
          applyAll: (instance_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const applied: string[] = [];
            const filters = instance.local.views.analysis.filters.company.data.get();
            Object.values(filters).forEach((filter) => {
              if (filter.checked) {
                applied.push(filter.id);
              }
            });
            instance.local.views.analysis.filters.applied_filters.company_id.set(applied);
          },
          toggle: (instance_id: string, filter_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            legend.features.filter.toggle({
              state: instance.local.views.analysis.filters.company,
              params: { id: filter_id },
            });
          },
          toggleGroup: (instance_id: string, filter_group: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            legend.features.filter.toggleGroup({
              state: instance.local.views.analysis.filters.company,
              params: { group: filter_group },
            });
          },
          getFiltersByGroup: (instance_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const out = legend.features.filter.getFiltersByGroup({
              state: instance.local.views.analysis.filters.company,
            });
            return out;
          },
        },
        topic: {
          applyAll: (instance_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const applied: string[] = [];
            const filters = instance.local.views.analysis.filters.topic.data.get();
            Object.values(filters).forEach((filter) => {
              if (filter.checked) {
                applied.push(filter.id);
              }
            });
            instance.local.views.analysis.filters.applied_filters.topic_name.set(applied);
          },
          toggle: (instance_id: string, filter_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            legend.features.filter.toggle({
              state: instance.local.views.analysis.filters.topic,
              params: { id: filter_id },
            });
          },
          toggleGroup: (instance_id: string, filter_group: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            legend.features.filter.toggleGroup({
              state: instance.local.views.analysis.filters.topic,
              params: { group: filter_group },
            });
          },
          getFiltersByGroup: (instance_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const out = legend.features.filter.getFiltersByGroup({
              state: instance.local.views.analysis.filters.topic,
            });
            return out;
          },
        },
        timeframe: {
          applyAll: (instance_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const applied: string[] = [];
            const filters = instance.local.views.analysis.filters.timeframe.data.get();
            Object.values(filters).forEach((filter) => {
              if (filter.checked) {
                applied.push(filter.id);
              }
            });
            instance.local.views.analysis.filters.applied_filters.timeframe.set(applied);
          },
          toggle: (instance_id: string, filter_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            legend.features.filter.toggle({
              state: instance.local.views.analysis.filters.timeframe,
              params: { id: filter_id },
            });
          },
          toggleGroup: (instance_id: string, filter_group: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            legend.features.filter.toggleGroup({
              state: instance.local.views.analysis.filters.timeframe,
              params: { group: filter_group },
            });
          },
          toggleByRange: (instance_id, range) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const filters_obs = instance.local.views.analysis.filters.timeframe.data;
            const filters_arr = Object.values(filters_obs.get());
            const [startYear, endYear] = range.split("-");

            // For single year selection
            if (!endYear) {
              const targetFilter = filters_arr.find((filter) => filter.id === startYear);
              const newCheckedState = !targetFilter?.checked;

              filters_arr.forEach((filter) => {
                const checked = filter.id === startYear ? newCheckedState : false;
                filters_obs[filter.id].checked.set(checked);
              });
              return;
            }

            // For year range selection
            filters_arr.forEach((filter) => {
              const year = parseInt(filter.id);
              const inRange = year >= parseInt(startYear) && year <= parseInt(endYear);
              // If all are checked, uncheck them; if not all checked, check them
              const checked = inRange;
              filters_obs[filter.id].checked.set(checked);
            });
          },
          getFiltersByGroup: (instance_id: string) => {
            const instance = rc.registry.actions.getInstance(instance_id);
            const out = legend.features.filter.getFiltersByGroup({
              state: instance.local.views.analysis.filters.timeframe,
            });
            return out;
          },
        },
      },
    },
  },
};

export const observers: IObservers = {
  debug: (instance_id: string) => {
    // const instance = rc.registry.actions.getInstance(instance_id);
    // const local = instance.local.get();
    // const remote = instance.remote.get();
    // const peerset = report_builder.remote.peerset.data.get();
    // console.log("State", {
    //   local_state: local,
    //   remote_state: remote,
    //   peerset,
    // });
  },
  view: {
    analysis: {
      filters: {
        sentiment: {
          init: (instance_id: string) => {
            if (!instance_id) return;
            legend.state.observe<{ initialised: boolean }>((e) => {
              if (e.previous?.initialised) return;
              const instance = rc.registry.actions.getInstance(instance_id);
              const sentiments = instance.local.config.sentiment.sentiments.get();

              if (!sentiments || sentiments.length === 0) return;

              // populate filter state
              legend.state.batch(() => {
                legend.features.filter.batchAddFilter({
                  state: instance.local.views.analysis.filters.sentiment,
                  params: {
                    data: sentiments.map((sentiment) => {
                      const out = {
                        id: sentiment.id,
                        label: sentiment.label,
                        checked: sentiment.filter.checked,
                        disabled: sentiment.filter.disabled,
                        group: "",
                      };
                      return out;
                    }),
                  },
                });
                actions.views.analysis.filters.sentiment.applyAll(instance_id);
              });

              return { initialised: true };
            });
          },
        },
        company: {
          init: (instance_id: string) => {
            if (!instance_id) return;
            legend.state.observe((e) => {
              const instance = rc.registry.actions.getInstance(instance_id);
              const peerset = report_builder.remote.peerset.data.get();

              if (Object.keys(peerset).length === 0) return;

              const companies = Object.values(peerset);

              const record = companies.reduce(
                (acc, company) => {
                  acc[company.company_id] = {
                    id: company.company_id,
                    label: company.company_name,
                    checked: true,
                    disabled: false,
                    group: "",
                  };
                  return acc;
                },
                {} as Record<string, IFilter>,
              );

              legend.state.batch(() => {
                instance.local.views.analysis.filters.company.data.set(record);
                instance.local.views.analysis.filters.applied_filters.company_id.set(Object.keys(record));
              });
            });
          },
        },
        topic: {
          init: (instance_id: string) => {
            if (!instance_id) return;
            legend.state.observe<{ initialised: boolean }>((e) => {
              if (e.previous?.initialised) return;
              const instance = rc.registry.actions.getInstance(instance_id);
              const topic_metadata = metadata.remote$.data.get().cached_transformed["topic"];

              if (!topic_metadata || topic_metadata.length === 0) return;

              // populate filter state
              legend.state.batch(() => {
                legend.features.filter.batchAddFilter({
                  state: instance.local.views.analysis.filters.topic,
                  params: {
                    data: topic_metadata.map((topic) => ({
                      id: topic.meta_data_value,
                      label: topic.meta_data_value,
                      checked: true,
                      disabled: false,
                      group: topic.meta_data_parent_value ?? "",
                    })),
                  },
                });
                actions.views.analysis.filters.topic.applyAll(instance_id);
              });

              return { initialised: true };
            });
          },
        },
        timeframe: {
          init: (instance_id: string) => {
            if (!instance_id) return;
            legend.state.observe<{ initialised: boolean }>((e) => {
              if (e.previous?.initialised) return;
              const instance = rc.registry.actions.getInstance(instance_id);
              const timeframe_metadata = metadata.remote$.data.get().cached_transformed["year"];
              const company_search_timeframe = report_builder.local.search.time_periods.get();

              if (!timeframe_metadata || timeframe_metadata.length === 0 || company_search_timeframe.length === 0) {
                return { initialised: false };
              }

              // populate filter state
              legend.state.batch(() => {
                legend.features.filter.batchAddFilter({
                  state: instance.local.views.analysis.filters.timeframe,
                  params: {
                    data: timeframe_metadata.map((timeframe) => {
                      const out = {
                        id: timeframe.meta_data_value,
                        label: timeframe.meta_data_value.toLowerCase(),
                        checked: company_search_timeframe?.includes(timeframe.meta_data_value) ?? false,
                        disabled: false,
                        group: "",
                      };
                      return out;
                    }),
                  },
                });
                actions.views.analysis.filters.timeframe.applyAll(instance_id);
              });

              return { initialised: true };
            });
          },
        },
      },
    },
  },
};

const helpers = {
  validateAndMergeState: (state: ILocalState$, new_state?: object) => {
    if (new_state !== undefined) {
      const isValid = local_schema.safeParse(new_state);
      if (!isValid.success) {
        throw new Error(
          `Invalid props provided to createState$. Validation errors: ${JSON.stringify(isValid.error.format(), null, 2)}`,
        );
      }
      state = merge(state, new_state);
      return state;
    }
    return state;
  },

  transformMeasureScore: (measure: IMeasure, measure_type: string) => {
    if (measure_type === "NSS") {
      const score = transforms.nss.toPercentage(measure.measure_value);
      return score;
    } else if (measure_type === "PREVALENCE") {
      const score = transforms.prevalence.toPercentage(measure.measure_value);
      return score;
    } else if (measure_type === "RATING") {
      const score = transforms.rating.toNumber(measure.measure_value);
      return score;
    } else if (measure_type === "REVIEW_COUNT") {
      const score = transforms.count.toNumber(measure.measure_value);
      return score;
    }

    return null;
  },
};

// Option 1: Sort and compare
const arraysAreEqual = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) return false;
  return [...arr1].sort().join(",") === [...arr2].sort().join(",");
};

// Option 2: Using Set
const arraysHaveSameElements = (arr1: string[], arr2: string[]) => {
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);
  return arr1.length === arr2.length && arr1.every((item) => set2.has(item)) && arr2.every((item) => set1.has(item));
};
