import { employee_voice_single_company_correlation } from "./culture/employee-voice/correlation";
import { employee_voice_single_company_multi_topic_trend } from "./culture/employee-voice/mutli-topic-trend";
import { employee_voice_company_personality } from "./culture/employee-voice/company-personality";
import { employee_voice_multi_company_single_topic_trend } from "./culture/employee-voice/single-topic-trend";
import { employee_voice_company_ranking } from "./culture/employee-voice/company-ranking";
import { employee_voice_extracts } from "./culture/employee-voice/extracts";
import { LazyExoticComponent } from "react";
import _ from "lodash";
import { IRC, IRC_Required_Context } from "./types";

const registry = {
  culture: {
    [employee_voice_single_company_correlation.rc.rc_id]: employee_voice_single_company_correlation,
    [employee_voice_single_company_multi_topic_trend.rc.rc_id]: employee_voice_single_company_multi_topic_trend,
    [employee_voice_company_personality.rc.rc_id]: employee_voice_company_personality,
    [employee_voice_multi_company_single_topic_trend.rc.rc_id]: employee_voice_multi_company_single_topic_trend,
    [employee_voice_company_ranking.rc.rc_id]: employee_voice_company_ranking,
    [employee_voice_extracts.rc.rc_id]: employee_voice_extracts,
  },
};

interface IGetRCComponentOptions {
  throwError?: boolean;
}

function resolve_RC_component(id: string, options?: IGetRCComponentOptions) {
  const rc = findPropertyByName<{ rc: IRC<unknown, unknown, IRC_Required_Context> }>(registry, id)?.rc;
  if (!rc) {
    if (options?.throwError) {
      throw new Error(`Component with id ${id} not found`);
    }
    return null;
  }
  if (!isLazyExoticComponent(rc.instance.component)) {
    if (options?.throwError) {
      throw new Error(`Component with id ${id} is not a lazy component`);
    }
    return null;
  }
  return rc.instance.component;
}

function isLazyExoticComponent(value: unknown): value is LazyExoticComponent<any> {
  return (
    typeof value === "object" &&
    value !== null &&
    "$$typeof" in value &&
    (value as any).$$typeof === Symbol.for("react.lazy")
  );
}

function findPropertyByName<T>(obj: object, key: string): T | undefined {
  let result: T | undefined;
  const seen = new Set();

  const search = (obj: object) => {
    if (!obj || typeof obj !== "object") return;
    if (seen.has(obj)) return;

    seen.add(obj);

    if (key in obj) {
      result = (obj as Record<string, T>)[key];
      return;
    }

    Object.values(obj).forEach((value) => {
      if (typeof value === "object" && value !== null) {
        search(value);
      }
    });
  };

  search(obj);
  return result;
}

export const rc_registry = {
  resolve_RC_component,
};
