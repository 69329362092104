import { ICompany, ICompanySector, IContentExtract, IMetadata } from "@/core/shared/api/types";
import { filterStateSchema, IGetFiltersByGroupResult } from "@/core/utils/legend-state/feature/filter";
import { z } from "zod";

const config = z.object({
  company: z.object({
    measure_id_to_measure_name_map: z.record(z.string()),
    filters: z.object({
      hide: z.boolean(),
    }),
  }),
  measure: z.object({
    hide: z.boolean(),
    default: z.string(),
    measures: z.array(
      z.object({
        id: z.string(),
        label: z.string(),
        unit: z.string().optional(),
        acronym: z.string().optional(),
        showSign: z.boolean().optional(),
        domain: z.tuple([z.number(), z.number()]).optional(),
        compactYAxisValues: z.boolean().optional(),
        measure_name: z.string(),
        filter: z.object({
          checked: z.boolean(),
          disabled: z.boolean().optional(),
        }),
      }),
    ),
  }),
  sort: z.object({
    hide: z.boolean(),
    default: z.string(),
    directions: z.array(
      z.object({
        id: z.string(),
        label: z.string(),
        filter: z.object({
          enabled: z.boolean(),
          disabled: z.boolean(),
          applied: z.boolean(),
        }),
      }),
    ),
  }),
});

export const local_schema = z.object({
  label: z.string(),
  ui_merged: z.boolean(),
  config,
  views: z.object({
    currentView: z.union([z.literal("analysis"), z.literal("alerts")]),
    analysis: z.object({
      selectors: z.object({
        sort_direction: z.object({
          selectedSortDirectionId: z.string(),
        }),
        measure: z.object({
          selectedMeasureId: z.string(),
        }),
      }),
      filters: z.object({
        company: filterStateSchema,
        timeframe: filterStateSchema,
        applied_filters: z.object({
          company_id: z.array(z.string()).optional(),
          timeframe: z.array(z.string()).optional(),
        }),
      }),
    }),
  }),
});

export type ILocalState$ = z.infer<typeof local_schema>;

export type IRemoteState$ = {
  companies: {
    pending: boolean;
    error: boolean;
    data: ITransformedCompany[];
  };
  evidence: {
    pending: boolean;
    error: boolean;
    data: Record<string, Record<string, Record<string, IContentExtract[]>>>;
  };
};

export interface ICreateStateProps {
  local?: object;
}

export interface IActions {
  views: {
    analysis: {
      selectors: {
        measure: {
          set: (instance_id: string, measure_id: string) => void;
        };
      };
      filters: {
        company: {
          applyAll: (instance_id: string) => void;
          toggle: (instance_id: string, filter_id: string) => void;
          toggleGroup: (instance_id: string, filter_group: string) => void;
          getFiltersByGroup: (instance_id: string) => IGetFiltersByGroupResult;
        };
        timeframe: {
          applyAll: (instance_id: string) => void;
          toggle: (instance_id: string, filter_id: string) => void;
          toggleGroup: (instance_id: string, filter_group: string) => void;
          toggleByRange: (instance_id: string, range: `${number}-${number}`) => void;
          getFiltersByGroup: (instance_id: string) => IGetFiltersByGroupResult;
        };
      };
    };
  };
}

export interface IObservers {
  view: {
    analysis: {
      filters: {
        company: {
          init: (instance_id: string) => void;
        };
        timeframe: {
          init: (instance_id: string) => void;
        };
      };
    };
  };
}

export interface IHelpers {
  validateAndMergeState: (state: ILocalState$, new_state?: object) => ILocalState$;
  transformCompany: (
    company: ICompany,
    metadata: IMetadata[],
    measure: z.infer<typeof config>["measure"]["measures"][number],
  ) => ITransformedCompany;
}

export interface ITransformedCompany
  extends Omit<
    ICompany,
    | "sectors"
    | "nss_per_company"
    | "prevalence_per_company"
    | "count_per_company"
    | "rating_per_company"
    | "company_headcount"
    | "company_headquarters"
  > {
  measure_score: string | null;
  sectors: {
    primary: ICompanySector[];
    secondary: ICompanySector[];
  };
  signal_strength: "SIGNAL" | "LOW_SIGNAL" | "NO_SIGNAL";
  company_headquarters?: {
    id?: string;
    label?: string;
  };
  nss_per_company: {
    percentage: number | null;
    percentage_string: string | null;
    strength: "high" | "medium" | "low" | "neutral" | null;
    sentiment: "pos" | "neg" | "neutral" | null;
  };
  prevalence_per_company: {
    percentage: number | null;
    percentage_string: string | null;
  };
  count_per_company: {
    count: number | null;
    compact_number: string | null;
    strength: "low" | "ok" | null;
  };
  rating_per_company: {
    rating: number | null;
  };
  company_headcount: {
    compact_number: string | null;
  };
}

export interface ITransformedContentExtract extends IContentExtract {
  company: ITransformedCompany | null;
}
