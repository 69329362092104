import * as state from "@legendapp/state";
import * as react from "@legendapp/state/react";
import * as sync from "@legendapp/state/sync";
import * as local_storage from "@legendapp/state/persist-plugins/local-storage";
import * as tanstack_query from "@legendapp/state/sync-plugins/tanstack-query";
import * as filter from "./feature/filter";
import * as pagination from "./feature/pagination";

function awaitObservable<T>(
  dataObservable: state.Observable<T>,
  pendingObservable: state.Observable<boolean>,
  errorObservable: state.Observable<boolean>,
  options?: { timeout?: number },
): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    let timeoutId: NodeJS.Timeout | undefined;

    if (options?.timeout) {
      timeoutId = setTimeout(() => {
        reject(new Error("Failed to fetch observable data: Timeout exceeded"));
        dispose();
      }, options.timeout);
    }

    const dispose = legend.state.observe(dataObservable, () => {
      const data = dataObservable.get();
      const isPending = pendingObservable.get();
      const isError = errorObservable.get();

      if (isError) {
        if (timeoutId) clearTimeout(timeoutId);
        reject(new Error("Failed to fetch observable data: An error occurred while waiting for the data"));
        dispose();
      }

      if (!isPending && data) {
        if (timeoutId) clearTimeout(timeoutId);
        resolve(data);
        dispose();
      }
    });
  });
}

export const legend = {
  state,
  react,
  sync,
  local_storage,
  tanstack_query,
  features: {
    filter,
    pagination,
  },
  utils: {
    awaitObservable,
  },
};
