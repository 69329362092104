import { Observable } from "@legendapp/state";
import { legend } from "../core";
import { ui$ } from "@/core/state/company-search";
import { remote$ } from "@/core/state/company-search";

export interface IPaginator {
  page_size: number;
  total_pages: number;
  current_page: number;
  total_count: number;
  page_start: number;
  page_end: number;
}

export interface IPaginatorState {
  data: IPaginator;
}

export type IObservablePaginatorState = Observable<IPaginatorState>;

interface ICreateObservableStateProps {
  page_size: number;
}

export function createObservableState(props: ICreateObservableStateProps): IPaginatorState {
  return {
    data: {
      page_size: props.page_size,
      total_pages: 0,
      current_page: 1,
      total_count: 0,
      page_start: 0,
      page_end: 0,
    },
  };
}

interface IIncrementProps {
  state: IObservablePaginatorState;
  options?: {};
}

export function increment(props: IIncrementProps) {
  const total_pages = props.state.data.total_pages.get();
  const page_size = props.state.data.page_size.get();
  let current_page = props.state.data.current_page.get();
  let page_start = props.state.data.page_start.get();
  let page_end = props.state.data.page_end.get();

  if (current_page >= total_pages) return;

  current_page += 1;
  page_start = (current_page - 1) * page_size;
  page_end = page_start + page_size;

  legend.state.batch(() => {
    props.state.data.current_page.set(current_page);
    props.state.data.page_start.set(page_start);
    props.state.data.page_end.set(page_end);
  });
}

interface IDecrementProps {
  state: IObservablePaginatorState;
  options?: {};
}

export function decrement(props: IDecrementProps) {
  const page_size = props.state.data.page_size.get();

  let current_page = props.state.data.current_page.get();
  let page_start = props.state.data.page_start.get();
  let page_end = props.state.data.page_end.get();

  if (current_page <= 1) return;
  current_page -= 1;
  page_start = (current_page - 1) * page_size;
  page_end = page_start + page_size;

  legend.state.batch(() => {
    props.state.data.current_page.set(current_page);
    props.state.data.page_start.set(page_start);
    props.state.data.page_end.set(page_end);
  });
}

interface IDecrementToStartProps {
  state: IObservablePaginatorState;
  options?: {};
}

export function decrementToStart(props: IDecrementToStartProps) {
  const page_size = props.state.data.page_size.get();
  let page_start = props.state.data.page_start.get();
  let current_page = props.state.data.current_page.get();

  current_page = 1;
  page_start = 0;
  const page_end = page_start + page_size;

  legend.state.batch(() => {
    props.state.data.current_page.set(current_page);
    props.state.data.page_start.set(page_start);
    props.state.data.page_end.set(page_end);
  });
}

interface IIncrementToEndProps {
  state: IObservablePaginatorState;
  options?: {};
}

export function incrementToEnd(props: IIncrementToEndProps) {
  const page_size = props.state.data.page_size.get();
  const total_pages = props.state.data.total_pages.get();
  let current_page = props.state.data.current_page.get();
  let page_end = props.state.data.page_end.get();

  current_page = total_pages;
  const page_start = (total_pages - 1) * page_size;
  page_end = page_start + page_size;

  legend.state.batch(() => {
    props.state.data.current_page.set(current_page);
    props.state.data.page_start.set(page_start);
    props.state.data.page_end.set(page_end);
  });
}
