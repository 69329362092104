import { cn } from "@/core/utils/tailwind-toolkit/core";
import { useState } from "react";

interface Props {
  characterLimit: number;
  children: string;
  classNames?: {
    container?: string;
    text?: string;
    readMore?: string;
  };
  expandedText?: string;
  collapsedText?: string;
}

export function TruncatedText(props: Props) {
  const [isExpanded, setIsExpanded] = useState(false);
  const limited = isExpanded ? props.children : props.children.slice(0, props.characterLimit);
  return (
    <div className={props.classNames?.container}>
      <span
        className={cn(
          "w-full text-gray-600 inline-block text-xs font-normal text-muted-foreground",
          props.classNames?.text,
        )}
      >
        {limited}
        {props.children.length > props.characterLimit && (
          <span
            onClick={(event) => {
              event.stopPropagation();
              setIsExpanded(!isExpanded);
            }}
            className={cn("cursor-pointer font-bold text-muted-foreground", props.classNames?.readMore)}
          >
            ...
            <span className="hover:underline">
              {isExpanded ? (props.collapsedText ?? "Read less") : (props.expandedText ?? "Read more")}
            </span>
          </span>
        )}
      </span>
    </div>
  );
}
