// Evidence filter should be driven by the analysis filters
// e.g company, topic, timeframe
// Sentiment shouldnt be a filter for evidence as it only shows 1 positive and 1 negative.

import { IContentExtract } from "@/core/shared/api";
import { filterStateSchema, IGetFiltersByGroupResult } from "@/core/utils/legend-state/feature/filter";
import { IPeersetCompany } from "@/features/report-builder/state/types";
import { z } from "zod";

export const entity_chart_config_schema = z.record(
  z.string(),
  z.object({
    id: z.string(),
    label: z.string(),
    color: z.string(),
  }),
);

const axis_schema = z.object({
  data_key: z.string().optional(),
  label: z.string().optional(),
  compactValues: z.boolean().optional(),
  domain: z.tuple([z.number(), z.number()]).optional(),
  unit: z.string().optional(),
});

export const analysis_chart_config_schema = z.object({
  entities: entity_chart_config_schema,
  x_axis: axis_schema,
  y_axis: axis_schema,
});

export const local_schema = z.object({
  label: z.string(),
  ui_merged: z.boolean(),
  config: z.object({
    company: z.object({
      measure_filter_to_measure_name_map: z.record(z.string()),
      filters: z.object({
        hide: z.boolean(),
      }),
    }),
    measure: z.object({
      hide: z.boolean(),
      default: z.string(),
      measures: z.array(
        z.object({
          id: z.string(),
          label: z.string(),
          unit: z.string().optional(),
          acronym: z.string().optional(),
          showSign: z.boolean().optional(),
          domain: z.tuple([z.number(), z.number()]).optional(),
          compactYAxisValues: z.boolean().optional(),
          filter: z.object({
            applied: z.boolean(),
            disabled: z.boolean().optional(),
          }),
        }),
      ),
    }),
  }),
  views: z.object({
    currentView: z.union([z.literal("analysis"), z.literal("alerts")]),
    analysis: z.object({
      chart_config: analysis_chart_config_schema,
      selectors: z.object({
        company: z.object({
          selectedCompanyId: z.string().nullable(),
        }),
        y_axis_measure: z.object({
          selectedMeasureId: z.string(),
        }),
      }),
      filters: z.object({
        topic: filterStateSchema,
        timeframe: filterStateSchema,
        applied_filters: z.object({
          company_id: z.array(z.string()).optional(),
          topic_name: z.array(z.string()).optional(),
          timeframe: z.array(z.string()).optional(),
        }),
      }),
    }),
  }),
});

export type ILocalState$ = z.infer<typeof local_schema>;

export interface IRemoteState$ {
  measures: {
    pending: boolean;
    error: boolean;
    data: Record<string, number | string | null>[];
  };
  evidence: {
    pending: boolean;
    error: boolean;
    data: Record<string, Array<ITransformedContentExtract>>;
  };
}

export interface ICreateStateProps {
  local?: object;
}

export interface IActions {
  views: {
    analysis: {
      selectors: {
        y_axis_measure: {
          set: (instance_id: string, measure_id: string) => void;
        };
      };
      filters: {
        topic: {
          applyAll: (instance_id: string) => void;
          toggle: (instance_id: string, filter_id: string) => void;
          toggleGroup: (instance_id: string, filter_group: string) => void;
          getFiltersByGroup: (instance_id: string) => IGetFiltersByGroupResult;
        };
        timeframe: {
          applyAll: (instance_id: string) => void;
          toggle: (instance_id: string, filter_id: string) => void;
          toggleGroup: (instance_id: string, filter_group: string) => void;
          toggleByRange: (instance_id: string, range: `${number}-${number}`) => void;
          getFiltersByGroup: (instance_id: string) => IGetFiltersByGroupResult;
        };
      };
    };
  };
}

export interface IObservers {
  view: {
    analysis: {
      chart_config: {
        sync_to_state: (instance_id: string) => void;
      };
      selectors: {
        company: {
          init: (instance_id: string) => void;
        };
      };
      filters: {
        topic: {
          init: (instance_id: string) => void;
        };
        timeframe: {
          init: (instance_id: string) => void;
        };
      };
    };
  };
}

export interface ITransformedContentExtract extends IContentExtract {
  company: IPeersetCompany | null;
}
