import React from "react";

// Create a generic reusable utility function for fetching context
export function useContextWithGuard<T>(context: React.Context<T | null>, errorMessage: string): T {
  const contextValue = React.useContext(context);
  if (contextValue === null) {
    throw new Error(errorMessage);
  }
  return contextValue;
}

/**
 * Creates a context provider with associated context and hook.
 *
 * @template T The type of the context value
 * @param {T | null} defaultValue The default value for the context (optional)
 * @returns {{ Context: React.Context<T | null>, Provider: React.FC<{ value: T; children: React.ReactNode }>, useContext: () => T }}
 *
 * @important
 * Context is intended for dependency injection of props, not for state management.
 * If state management is required, consider using libraries like zustand or valtio instead.
 */
export function createContextProvider<T>(name?: string, defaultValue: T | null = null) {
  const Context = React.createContext<T | null>(defaultValue);

  const Provider: React.FC<{ value: T; children: React.ReactNode }> = ({ value, children }) => {
    return <Context.Provider value={value}>{children}</Context.Provider>;
  };

  const useContext = () =>
    useContextWithGuard(Context, `${Context.displayName || name} must be used within its provider`);

  return { Context, Provider, useContext };
}
