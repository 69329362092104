import { createTestId } from "@/core/utils/test-ids";

export const testIds = {
  mainContent: createTestId("company-search", "main-content"),
  header: createTestId("company-search", "header"),
  contentContainer: createTestId("company-search", "content-container"),
  pageTitle: createTestId("company-search", "page-title"),
  searchControls: createTestId("company-search", "search-controls"),
  searchBarContainer: createTestId("company-search", "search-bar-container"),
  filterContainer: createTestId("company-search", "filter-container"),
  filterGroup: createTestId("company-search", "filter-group"),
  components: {
    benchmarkButton: createTestId("company-search", "benchmark-button"),
    searchBar: {
      container: createTestId("search-bar", "container"),
      inputWrapper: createTestId("search-bar", "input-wrapper"),
      searchIcon: createTestId("search-bar", "search-icon"),
      input: createTestId("search-bar", "input"),
    },
    sectorFilter: {
      trigger: createTestId("sector-filter", "trigger"),
      dialog: createTestId("sector-filter", "dialog"),
      header: createTestId("sector-filter", "header"),
      breadcrumb: createTestId("sector-filter", "breadcrumb"),
      closeButton: createTestId("sector-filter", "close-button"),
      title: createTestId("sector-filter", "title"),
      loading: createTestId("sector-filter", "loading"),
      content: createTestId("sector-filter", "content"),
      search: createTestId("sector-filter", "search"),
      selected: {
        container: createTestId("sector-filter", "selected-container"),
        heading: createTestId("sector-filter", "selected-heading"),
        empty: createTestId("sector-filter", "selected-empty"),
        list: createTestId("sector-filter", "selected-list"),
        item: createTestId("sector-filter", "selected-item"),
        removeButton: createTestId("sector-filter", "selected-remove-button"),
      },
      list: {
        container: createTestId("sector-filter", "list-container"),
        heading: createTestId("sector-filter", "list-heading"),
        grid: createTestId("sector-filter", "list-grid"),
        empty: createTestId("sector-filter", "list-empty"),
        item: createTestId("sector-filter", "list-item"),
      },
      actions: {
        container: createTestId("sector-filter", "actions"),
        clearButton: createTestId("sector-filter", "clear-button"),
        applyButton: createTestId("sector-filter", "apply-button"),
      },
    },
    timeRangeFilter: {
      trigger: createTestId("time-range-filter", "trigger"),
      dialog: createTestId("time-range-filter", "dialog"),
      header: createTestId("time-range-filter", "header"),
      breadcrumb: createTestId("time-range-filter", "breadcrumb"),
      closeButton: createTestId("time-range-filter", "close-button"),
      title: createTestId("time-range-filter", "title"),
      loading: createTestId("time-range-filter", "loading"),
      content: createTestId("time-range-filter", "content"),
      search: createTestId("time-range-filter", "search"),
      selected: {
        container: createTestId("time-range-filter", "selected-container"),
        heading: createTestId("time-range-filter", "selected-heading"),
        empty: createTestId("time-range-filter", "selected-empty"),
        list: createTestId("time-range-filter", "selected-list"),
        item: createTestId("time-range-filter", "selected-item"),
        removeButton: createTestId("time-range-filter", "selected-remove-button"),
      },
      quickFilters: {
        container: createTestId("time-range-filter", "quick-filters-container"),
        heading: createTestId("time-range-filter", "quick-filters-heading"),
        grid: createTestId("time-range-filter", "quick-filters-grid"),
        empty: createTestId("time-range-filter", "quick-filters-empty"),
      },
      yearFilters: {
        container: createTestId("time-range-filter", "year-filters-container"),
        heading: createTestId("time-range-filter", "year-filters-heading"),
        grid: createTestId("time-range-filter", "year-filters-grid"),
        empty: createTestId("time-range-filter", "year-filters-empty"),
        item: createTestId("time-range-filter", "year-filters-item"),
      },
      actions: {
        container: createTestId("time-range-filter", "actions"),
        clearButton: createTestId("time-range-filter", "clear-button"),
        applyButton: createTestId("time-range-filter", "apply-button"),
      },
    },
    resultsTable: {
      container: createTestId("results-table", "container"),
      content: createTestId("results-table", "content"),
      companyCard: {
        container: createTestId("results-table", "company-card"),
        header: createTestId("results-table", "company-card-header"),
        peerSetToggle: createTestId("results-table", "company-card-peer-set-toggle"),
        description: createTestId("results-table", "company-card-description"),
        tags: createTestId("results-table", "company-card-tags"),
      },
      sort: {
        select: createTestId("results-table", "sort-select"),
        trigger: createTestId("results-table", "sort-trigger"),
      },
      pagination: {
        container: createTestId("results-table", "pagination"),
        info: createTestId("results-table", "pagination-info"),
        controls: {
          prev: createTestId("results-table", "pagination-prev"),
          next: createTestId("results-table", "pagination-next"),
          start: createTestId("results-table", "pagination-start"),
          end: createTestId("results-table", "pagination-end"),
        },
      },
      noResults: {
        container: createTestId("results-table", "no-results-container"),
        title: createTestId("results-table", "no-results-title"),
        description: createTestId("results-table", "no-results-description"),
        emailInstructions: createTestId("results-table", "email-instructions"),
        emailLink: createTestId("results-table", "email-link"),
        emailDisplay: createTestId("results-table", "email-display"),
        exampleEmail: {
          container: createTestId("results-table", "example-email"),
          subject: createTestId("results-table", "example-subject"),
          company: createTestId("results-table", "example-company"),
          url: createTestId("results-table", "example-url"),
          description: createTestId("results-table", "example-description"),
          note: createTestId("results-table", "example-note"),
        },
      },
      header: createTestId("results-table", "header"),
      tabButtons: {
        container: createTestId("results-table", "tab-buttons"),
        allCompanies: createTestId("results-table", "all-companies-tab"),
        peerSet: createTestId("results-table", "peer-set-tab"),
      },
    },
  },
} as const;
