import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function acronym(input: string | undefined, length: number): string {
  if (!input) return "-";
  // Split the input string into an array of words
  const words = input.split(" ");

  // Extract the first letter of each word and join them together to form the acronym
  let acronym = words.reduce((result, word) => result + word[0], "");

  // Limit the length of the acronym if necessary
  if (acronym.length > length) {
    acronym = acronym.slice(0, length);
  }

  return acronym.toUpperCase();
}
