import { Avatar, AvatarFallback, AvatarImage } from "@/core/shadcn/ui/avatar";
import { acronym } from "@/core/shadcn/utils";
import { cn } from "@/core/shadcn/utils";
import { toast } from "sonner";
import { AlertTriangle, CheckCircle } from "lucide-react";

function defaultMessage(message: string) {
  return toast.custom(() => (
    <div className="flex items-center gap-2 p-4 bg-[hsl(var(--sidebar-background))] rounded-lg border border-sidebar-border shadow-shadow-xs">
      <span className="text-sm text-foreground">{message}</span>
    </div>
  ));
}

function defaultError(message: string) {
  return toast.custom(() => (
    <div className="flex items-center gap-2 p-4 bg-error-600 rounded-lg shadow-shadow-xs">
      <AlertTriangle className="h-4 w-4 text-white" />
      <span className="text-sm text-white">{message}</span>
    </div>
  ));
}

function defaultSuccess(message: string) {
  return toast.custom(() => (
    <div className="flex items-center gap-2 p-4 bg-success-600 rounded-lg shadow-shadow-xs">
      <CheckCircle className="h-4 w-4 text-white" />
      <span className="text-sm text-white">{message}</span>
    </div>
  ));
}

interface CompanyAssociatedMessageProps {
  company: { company_logo_src: string; company_name: string };
  suffix: string;
  color: "success" | "error";
}

function companyMessage({ company, suffix, color }: CompanyAssociatedMessageProps) {
  return toast.custom(() => (
    <div className="flex items-center gap-2 p-4 bg-[hsl(var(--sidebar-background))] rounded-lg border border-sidebar-border shadow-shadow-xs">
      <Avatar className="h-7 w-7 rounded-full border border-sidebar-border">
        <AvatarImage src={company.company_logo_src} alt={company.company_name} />
        <AvatarFallback className="rounded-full text-xs">{acronym(company.company_name, 2)}</AvatarFallback>
      </Avatar>
      <span className="text-sm text-foreground">
        {company.company_name}{" "}
        <span
          className={cn(
            color === "success" ? "dark:text-success-300 text-success-600" : "dark:text-error-400 text-error-600",
          )}
        >
          {suffix}
        </span>
      </span>
    </div>
  ));
}

export const notify = {
  defaultError,
  defaultMessage,
  companyMessage,
  defaultSuccess,
};
