import { actions, createState, observers } from "./state$";
import { local_schema } from "./types";

export type * as IState from "./types";

export const state = {
  createState,
  actions,
  local_schema,
  observers,
};
