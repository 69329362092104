import { legend } from "@/core/utils/legend-state/core";
import { Button } from "@/core/shadcn/ui/button";
import { ChevronDown, ChevronRight, Minus, Plus } from "lucide-react";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/core/shadcn/ui/collapsible";
import { report_builder } from "@/features/report-builder/state";
import { Avatar, AvatarFallback, AvatarImage } from "@/core/shadcn/ui/avatar";
import { acronym } from "@/core/shadcn/utils";

export const PeersetSettings = legend.react.observer(() => {
  const peers = report_builder.remote.peerset.data.get();
  return (
    <Collapsible
      defaultOpen
      className="px-4 py-4 w-full border-b border-border flex flex-col data-[state=open]:gap-[14px] group pb-6 data-[state=closed]:pb-4"
    >
      <div className="flex items-center justify-between gap-2 pt-0">
        <span className="text-sm font-medium">Peer Set</span>
        <CollapsibleTrigger asChild>
          <Button variant="outline" size="sm" className="p-1 h-auto w-auto">
            <ChevronDown className="h-4 w-4" />
          </Button>
        </CollapsibleTrigger>
      </div>
      <CollapsibleContent className="flex flex-col gap-[14px]">
        {Object.values(peers).map((peerset) => (
          <Button variant="outline" size="sm" className="w-full justify-start shadow-sm pointer-events-none">
            <Avatar className="w-5 h-5">
              <AvatarImage src={peerset.company_logo_src} />
              <AvatarFallback className="text-[10px]">{acronym(peerset.company_name, 2)}</AvatarFallback>
            </Avatar>
            <div className="w-full flex justify-start">{peerset.company_name}</div>
          </Button>
        ))}
      </CollapsibleContent>
    </Collapsible>
  );
});
