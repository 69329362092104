import { Button } from "@/core/shadcn/ui/button";
import { legend } from "@/core/utils/legend-state/core";
import { state } from "../../state/state";

export const ResetFiltersButton = legend.react.observer(() => {
  function handleResetFilters() {
    state.actions.filters.resetAll();
  }
  return (
    <Button variant="ghost" className="text-xs font-medium px-3 text-muted-foreground" onClick={handleResetFilters}>
      Reset Filters
    </Button>
  );
});
