import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { ReportBuilder } from "@/features/report-builder";
import { api } from "@/core/shared/api";
import { report_builder } from "@/features/report-builder/state";
import { uid } from "radash";
import { legend } from "@/core/utils/legend-state/core";

export const Route = createFileRoute("/report-builder")({
  validateSearch: z.object({
    peersetIds: z.array(z.string()).min(1, "At least one peerset must be selected"),
    timePeriods: z.array(z.string()),
  }),
  component: withAuthenticationRequired(() => <ReportBuilder />),
  beforeLoad: async (ctx) => {
    try {
      const currentPeersetIds = Object.keys(report_builder.remote.peerset.data.peek());
      const peersetIds = ctx.search.peersetIds;
      const timePeriods = ctx.search.timePeriods;
      legend.state.batch(() => {
        report_builder.local.search.peerset_ids.set(peersetIds);
        report_builder.local.search.time_periods.set(timePeriods);
        report_builder.local.search.report_id.set(uid(5, "report"));
        report_builder.remote.report.pending.set(true);
        report_builder.remote.report.progress.set(0);

        // Force reload of report
        // if (peersetIds.length !== currentPeersetIds.length) {
        //   report_builder.local.search.report_id.set(uid(5, "report"));
        //   report_builder.remote.report.pending.set(true);
        //   report_builder.remote.report.progress.set(0);
        // }
      });
    } catch (error) {
      // TODO: handle error
    }
  },
});
